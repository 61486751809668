import React from 'react'
import "../../../node_modules/video-react/dist/video-react.css";
import { Player, BigPlayButton } from 'video-react';

const VideoCard = ({videoTitle, videoContent, videoUrl}) => {
  return (
    <div className='h-auto w-full border border-primaryColor rounded-b-lg'>
      <Player
        playsInline
        src={videoUrl}
      > 
        <BigPlayButton position='center'/>
      </Player>
      <div className='px-4 py-3'>
        <div className='text-md font-bold'>{videoTitle}</div>
        <div className='text-paraText text-sm px-1 mt-2'>{videoContent}</div>
      </div>

    </div>

  )
}

export default VideoCard
