import React from 'react'

const PolicyContent = ({ titleText, contentText }) => {
    return (
        <div className='mt-10 md:px-0 px-3'>
            <div className='text-xl font-semibold'>
                {titleText}
            </div>
            <div className='text-paraText text-sm mt-3 leading-loose'>
                {contentText.split('\n').map((line, index) => <span key={index}>{line}<br /></span>)}
            </div>
        </div>
    )
}

export default PolicyContent
