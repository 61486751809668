import React, { useState } from 'react'
import Layout from '../../components/Layout/Layout'
import Button from '../../components/Common/Button'
import ScreenHeader from '../../components/Common/ScreenHeader'
import { useQuery } from '@tanstack/react-query'
import ScrollToTop from '../../services/scrollToTop'
import Footer from '../../components/HomePage/Footer'
import CustomHelmet from '../../components/Common/CustomHelmet'
import { HiOutlineX, HiCloudDownload } from "react-icons/hi";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import * as utlis from '../../services/utlis'


const GalleryScreen = () => {


    const [currentTab, setCurrentTab] = useState('fitness-and-mindfulness');
    const [imageLoading, setImageLoading] = useState(true);
    const [galleryViewModal, setGalleryViewModal] = useState(false);
    const [tempimgSrc, setTempImgSrc] = useState('');

    const getImg = (imgSrc) => {
        setTempImgSrc(imgSrc);
        setGalleryViewModal(true);
      }
    
    const { data: images, isLoading, isError, error, isSuccess } = useQuery({ queryKey: ['images', currentTab], queryFn: utlis.fetchImages});

    const handleImageLoading = () => {
        setImageLoading(false);
    }

    return (
        <Layout>
            <CustomHelmet pageTitle="Gallery" />
            <ScrollToTop />
            <ScreenHeader screenName="Gallery" />
            <div className='mt-10 container mx-auto h-auto font-primary sm:px-5'>
                <div className='h-auto flex flex-col justify-center items-center'>
                    <div className='text-sectionTitle font-semibold'>
                        Our <span className='text-primaryColor'>Gallery</span>
                    </div>
                    <div className='text-paraText text-sm mt-1 text-center p-4'>
                        Unveiling life's beauty through captivating moments in a diverse tapestry of visual stories and timeless impressions in frames
                    </div>
                </div>

                <div className="overflow-x-auto flex scrollbar-hide md:justify-center md:px-0 pl-5">
                    <div onClick={() => setCurrentTab('physical-training')}>
                        <Button title='Physical Training' black={currentTab !== 'physical-training'} />
                    </div>
                    <div className='ml-1' onClick={() => setCurrentTab('fitness-and-mindfulness')}>
                        <Button title='Fitness and Mindfulness' black={currentTab !== 'fitness-and-mindfulness'} />
                    </div>
                    <div className='ml-1' onClick={() => setCurrentTab('football')}>
                        <Button title='Football Club' black={currentTab !== 'football'} />
                    </div>
                    <div className='ml-1' onClick={() => setCurrentTab('library')}>
                        <Button title='Library' black={currentTab !== 'library'} />
                    </div>
                    <div className='ml-1' onClick={() => setCurrentTab('research-fellowship')}>
                        <Button title='Research Fellowship' black={currentTab !== 'research-fellowship'} />
                    </div>
                </div>


                {/* {isLoading || imageLoading &&
                    <div className='flex justify-center mt-20'>
                        <LoadingIndicator />
                    </div>} */}
                {isError && <div>{error.message}</div>}

                {isSuccess &&
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-10 px-1">
                        {images.filter((curImg) => curImg.gallery_tab === currentTab).map((image, index) => (
                            <div key={index} onClick={() => getImg(image.imageURL)}>
                                <LazyLoadImage src={image.imageURL} effect="blur" alt=""  className='w-96 h-72 rounded-lg object-cover transition duration-300 hover:cursor-pointer' onLoad={handleImageLoading} />
                            </div>
                        ))}
                    </div>
                }


            </div>
            <div className={galleryViewModal ? 'model open' : 'model'} id="activities">
                <img src={tempimgSrc} alt="" />
                <div>
                        <HiCloudDownload className='text-3xl cursor-pointer text-white save-photo-icon' onClick={() => utlis.savePhoto(tempimgSrc)}/>
                        <HiOutlineX className='text-3xl cursor-pointer text-white close-icon' onClick={() => setGalleryViewModal(false)}/>
                </div>

            </div>
            <Footer />
        </Layout>
    )
}

export default GalleryScreen
