import React from 'react'
import Layout from '../../components/Layout/Layout'

import ScreenHeader from '../../components/Common/ScreenHeader'
import ScrollToTop from '../../services/scrollToTop'
import ProgramCard from '../../components/Common/ProgramCard'
import { Link } from 'react-router-dom'
import { getIcon } from '../../services/getIcon'
import { allProgramCards } from '../../data'
import CustomHelmet from '../../components/Common/CustomHelmet'
import Footer from "../../components/HomePage/Footer";


const ProgramsScreen = () => {
    return (
        <Layout>
            <CustomHelmet pageTitle="Programs"/>
            <ScrollToTop />
            <ScreenHeader screenName="Programs" />
            <div className='container mx-auto mt-10 h-auto font-primary sm:px-5'>
                <div className='h-auto flex flex-col justify-center items-center'>
                    <div className='text-sectionTitle font-semibold'>
                        Our <span className='text-primaryColor'>Programs</span>
                    </div>
                    <div className='text-paraText text-sme mt-3'>
                        Here are the most featured voice of our students
                    </div>

                </div>
                <div className='grid lg:grid-cols-4 grid-cols-1 gap-4 mt-10 px-1 mx-3'>
                    {allProgramCards.map((programCardItem, index) => (
                        <Link to={programCardItem.route}>
                            <ProgramCard title={programCardItem.title} content={programCardItem.cardContent} key={index}>
                                {getIcon(programCardItem.iconType)}
                            </ProgramCard>
                        </Link>
                    ))}
                </div>
            </div>
            <Footer />
        </Layout>
    )
}

export default ProgramsScreen
