import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../../components/Layout/Layout';
import ScreenHeader from '../../components/Common/ScreenHeader';
import { allProgramCards } from '../../data/allPrograms';
import ScrollToTop from '../../services/scrollToTop';
import PluseLoading from '../../components/Common/PluseLoading';
import Footer from '../../components/HomePage/Footer';
import CustomHelmet from '../../components/Common/CustomHelmet';

const ProgramDetailScreen = () => {
    const { name: programName } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    const filteredProgram = () => {
        return allProgramCards.find(program => program.route === programName);
    }

    const handleImageLoad = () => {
        setIsLoading(false);
    }
    return (
        <Layout>
            <CustomHelmet pageTitle={filteredProgram().title}/>
            <ScrollToTop />
            <ScreenHeader screenName={filteredProgram().title} />
            <div className='container mx-auto mt-10 font-primary sm:px-5'>
                <div className={`grid ${filteredProgram().programImages.length > 0 ? 'lg:grid-cols-2' : 'grid-cols-1'} gap-4 grid-cols-1`}>

                    <div className='lg:px-0 px-3'>
                        <div className='text-sectionTitle font-semibold text-primaryColor'>{filteredProgram().title}</div>
                        <div className='text-paraText text-md mt-3 leading-loose'>
                            {filteredProgram().content}
                        </div>
                    </div>
                    { filteredProgram().programImages.length > 0 &&
                        <div className='grid grid-cols-2 gap-1'>
                            {isLoading && <PluseLoading />}
                            {
                                filteredProgram().programImages.map((programImg, index) => (

                                    <img src={programImg} key={index} alt="program-image" className='w-full h-72 rounded-lg object-cover cursor-pointer duration-300 transition hover:scale-95' onLoad={handleImageLoad} />

                                ))
                            }
                        </div>
                    }

                </div>
            </div>
            <Footer />
        </Layout>
    )
}

export default ProgramDetailScreen
