export const studentTestimonials = [
    {
        studentName: 'ဦးဝင်းမြင့်',
        fieldName: 'ပန်းပျိုးလက်',
        position: "ကျောင်းတာဝန်ခံ",
        message: `
        ကလေးငယ်များ၏ ပညာသင်ကြားရေးတွင် စိတ်ကျန်းမာရေးနှင့် ကိုယ်ခန္ဓာ ကြံ့ခိုင်သန်စွမ်းရေးအတွက် Myanmar Health Support (MHS) အဖွဲ့သူ အဖွဲ့သားများအနေဖြင့် စေတနာအလျောက် လာရောက်ကူညီအားဖြည့်ပေးသည့်အတွက် ကလေးငယ်များ၏ ကျန်းမာရေးဆိုင်ရာ ဗဟုသုတများ၊ စိတ်ခွန်အားဖြစ်စေသော ဗဟုသုတများနှင့် ကိုယ်ခန္ဓာ ကြံခိုင်းသန်စွမ်းရေးဆိုင်ရာ လေ့ကျင့်ခန်းများကို သင်ကြားပြသပေးခဲ့သည့်အတွက် ကလေးငယ်များ၏ စိတ်ကျန်းမာရေး၊ ကိုယ်ကျန်းမာရေးကို များစွာ အထောက်အကူဖြစ်စေခဲ့ပါသည်။
        သို့ဖြစ်ပါ၍ Myanmar Health Support (MHS) အဖွဲ့သူ အဖွဲ့သားများအား ပန်းပျိုးလက်-မဲဆောက် သင်ကြားရေးစင်တာ ဆရာ/ဆရာမများ အားလုံးမှ ကျေးဇူးတင် ဂုဏ်ပြုအပ်ပါသည်။`,
        imageUrl: 'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1701691053/Myanmar%20Health%20Support/testimonial/tmbfd1gfkoac5zayesqn.jpg'
    },
    {
        studentName: 'Scarlet',
        position:'ကထိက' ,
        message: 'The Myanmar Health Support is an organization that really helps the physical and mental health of migrant students in Maesot. I also see it as a self-help organization. I absolutely believe that if it can get full funding, it can really support the physical and mental health of migrant students according to their organization’s purpose. I suppose MHS is a good organization and it can be implemented well in the future.',
        imageUrl: 'https://i.pinimg.com/474x/2e/2f/ac/2e2fac9d4a392456e511345021592dd2.jpg'
    },
    {
        studentName: "Mr. John Alex",
        position: "Director /  Head Teacher",
        fieldName: "Maw Goon Thit - MLC",
        message: `I am writing to extend my heartfelt appreciation for the invaluable support provided to the students at Maw Goon Thit School. Your dedication to teaching physical 
        education, fostering mental strength, and imparting essential health knowledge has made a remarkable difference. The enthusiasm and commitment shown in guiding the children through mind-strengthening exercise and promoting
        physical fitness are genuinely admirable. Your contributions have significantly influenced our student's well-being, and your efforts are deeply valued. Thank you for your kindness and unwavering commitment to the holistic development of our children`,
        imageUrl: 'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1704562044/Myanmar%20Health%20Support/testimonial/413262180_333991599527180_3305047676771920721_n_kn2yxg.jpg'

    }
];