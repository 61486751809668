import React from 'react'

const AdminNotAuth = () => {
    return (
        <div className='mt-10 w-full flex justify-center items-center'>
            Login to continue

        </div>
    )
}

export default AdminNotAuth
