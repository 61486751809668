import useWeb3forms from "@web3forms/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

const ContactusForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useForm({
    mode: "onTouched",
  });

  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState(false);
  const [alert, setAlert] = useState(false);

  const apikey = "d6af5f4b-860b-4e98-8817-bd3706805eb9";
  const { submit: onSubmit } = useWeb3forms({
    access_key: apikey,
    settings: {
      from_name: "Myanmar Health Support",
      subject: "Hello world",
    },
    onSuccess: (msg, data) => {
      setIsSuccess(true);
      setMessage(msg);
      setAlert(true);
      reset();
    },
    onError: (msg, data) => {
      setIsSuccess(false);
      setMessage(msg);
    },
  });

  return (
    <div>
      <div className="text-primaryColor font-extrabold text-sectionTitle">
        Contact Us
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-10">
          <input
            type="hidden"
            name="access_key"
            value="d6af5f4b-860b-4e98-8817-bd3706805eb9"
          ></input>
          <div className="mb-6">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-white dark:text-white"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              className="bg-formBackground focus:outline-none text-white text-sm rounded-lg block w-full p-2.5"
              placeholder="John"
              required
              {...register("name")}
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-white dark:text-white"
            >
              Email Address
            </label>
            <input
              type="email"
              id="email"
              className="bg-formBackground focus:outline-none text-white text-sm rounded-lg block w-full p-2.5"
              placeholder="youremail@gmail.com"
              required
              {...register("email")}
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-white dark:text-white"
            >
              Your message
            </label>
            <textarea
              id="message"
              rows="4"
              className="block p-2.5 w-full text-sm text-white bg-formBackground rounded-lg focus:outline-none"
              placeholder="Hello, Myanmar Health Support ..."
              {...register("msg")}
            ></textarea>
          </div>
          <button
            className="bg-primaryColor w-full py-3 px-6 rounded-full text-xs md:text-base text-white"
            type="submit"
          >
            {isSubmitting ? (
              <svg
                className="w-5 h-5 mx-auto text-white dark:text-black animate-spin"
                xmlns="<http://www.w3.org/2000/svg>"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              "Send Message"
            )}
          </button>
          <div
            id="toast-simple"
            className={`fixed ${
              alert ? "bottom-5" : "bottom-[-400px]"
            } transition-all duration-500 right-5 flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 bg-white divide-x divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800`}
            role="alert"
          >
            <svg
              className="w-5 h-5 text-blue-600 dark:text-blue-500 rotate-45"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 18 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                stroke-width="2"
                d="m9 17 8 2L9 1 1 19l8-2Zm0 0V9"
              />
            </svg>
            <div className="pl-4 text-sm font-normal">
              Message sent successfully.
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactusForm;
