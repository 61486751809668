export const trimString= (str, length = 70) => {
    if(str.length > length) {
        return str.substring(0,length) + "...";
    } else {
        return str;
    }
}

export const monthSubString = (monthStr) => {
    return monthStr?.substring(0,3)
}