import React from 'react'

const StatusBadge = ({ status }) => {
    return (
        <div>
            {status ? <span className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full ">Online</span> 
            :
             <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full ">Offline</span>}
        </div>
    )
}

export default StatusBadge
