import React from 'react'
import { IoCheckmarkCircle } from 'react-icons/io5'
import { Link } from 'react-router-dom'

const Notifications = ({ notiType, notiMessage, notiTitle, eventId }) => {
    return (
        <div
            id="alert-additional-content-1"
            className="p-4 mb-4 text-blue-800 border border-blue-300 rounded-lg bg-blue-50"
            role="alert"
        >
            <div className="flex items-center">
                <IoCheckmarkCircle size='1.5rem' />
                <span className="sr-only">Info</span>
                <h3 className="text-lg font-medium ml-1">You have created an event!</h3>
            </div>
            <div className="mt-2 mb-4 text-sm">
                You successfully created an event. Click View Event to see what the website visitors can see.
            </div>
            <div className="flex">
                <Link to={`/event/${eventId}`}>
                    <button
                        type="button"
                        className="text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center"
                    >
                        <svg
                            className="me-2 h-3 w-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 14"
                        >
                            <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z" />
                        </svg>
                        View Event
                    </button>
                </Link>

            </div>
        </div>

    )
}

export default Notifications
