import axios from 'axios'

const axiosClient = axios.create();
// development
axiosClient.defaults.baseURL = 'https://api.myanmarhealthsupport.org/api/v1';

// pre production
// axiosClient.defaults.baseURL = 'https://myanmar-health-support-api.onrender.com/api/v1';
axiosClient.defaults.withCredentials = true;
// axiPPosClient.defaults.headers['Content-Type'] = 'application/json';
// axiosClient.defaults.headers['Accept'] = 'application/json';



export default axiosClient;