import { useQuery } from '@tanstack/react-query'
import React, { createContext, useContext } from 'react'
import axiosClient from '../services/network'

const PublisherContext = createContext();

export const PublisherContextProvider = ({children}) => {
    const fetchPublishers = async () => {
        const response = await axiosClient.get('/publishers')
        return response.data.data
    }

    const {data: publishers, isLoading, isError, error, isSuccess } = useQuery({queryKey: ['publishers'], queryFn: fetchPublishers})
    return (
        <PublisherContext.Provider value={{publishers, isLoading, isError, error, isSuccess}}>
            {children}
        </PublisherContext.Provider>
    )
}

export const useCustomPublishers = () => useContext(PublisherContext);
