export const organizeDate = ({ date, month, year }) => {
    return `${month} / ${date} /  ${year}`;
}

export const indextoMonth = (monthIndex) => {
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[monthIndex-1]
}


// usual date format
export const getDateFromEvent = (fullDate) => {
    return fullDate.getDate();
}

export const getMonthFromEvent = (fullDate) => {
    return fullDate.getMonth() + 1;
}

export const getDateDifference = (eventDate) => {
    // get date formatted in mm/dd/yy
    const date1 = formattedEventDate(eventDate);


    const diffTime = Math.abs(new Date(todayDate()) - new Date(date1));
    const diffDays = Math.ceil(diffTime/ (1000*60*60*24));

    return diffDays;
}

// get today date
const todayDate = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${month}/${date}/${year}`;
}

const formattedEventDate = (dateStr) => {
    const dateObj = new Date(dateStr);

    const month = dateObj.getMonth() + 1; // Adding 1 to get the correct month
    const day = dateObj.getDate();
    const year = dateObj.getFullYear().toString().slice(-2); // Extract the last two digits of the year

    // Format the date as mm/dd/yy
    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
}

export const compareDate = (eventDate) => {
    const currentDate = new Date(todayDate()).getTime(); // Call the todayDate function
    const eventFormattedDate = new Date(formattedEventDate(eventDate)).getTime();

    if (currentDate > eventFormattedDate) {
        return 'previous_event';
    } else if (currentDate < eventFormattedDate) {
        return 'upcoming_event';
    } else if (currentDate === eventFormattedDate){
        return 'today_event';
    } 
}

export const availableDate = (eventDate) => {
    const currentDate = new Date(todayDate()).getTime(); // Call the todayDate function
    const eventFormattedDate = new Date(formattedEventDate(eventDate)).getTime();

    return currentDate < eventFormattedDate;
}