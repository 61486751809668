import React, { useState } from 'react'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import { useCustomEvents } from '../../../context/eventContext';


const EventFilter = () => {
    // using event context for selected value
    const { eventSelectedOption,updateEventSelectedOption, setEventDateSelectedOption } = useCustomEvents();
    const [selectedEventType, setSelectedEventType] = useState('All Events');
    const [selectedTimeType, setSelectedTimeType] = useState('At any time')
    const [isDateRangeSelected, setDateRangeSelected] = useState(false);
    const [selectedDayRange, setSelectedDayRange] = useState({
        from: null,
        to: null
    });

    const getSelectedDayFromRange = (dayObj) => {
        return dayObj ? `${dayObj.day} / ${dayObj.month} / ${dayObj.year}` : '__';
    }

    const getSelectedDayToRange = (dayObj) => {
        return dayObj ? `${dayObj.day} / ${dayObj.month} / ${dayObj.year}` : '__';
    }

    const renderCustomCalenderInput = ({ ref }) => (
        <input
            readOnly
            ref={ref}
            placeholder='Select date range'
            value={selectedDayRange ? `${getSelectedDayFromRange(selectedDayRange.from)} - ${getSelectedDayToRange(selectedDayRange.to)}` : 'nothing'}
            className='z-[-100] py-1 px-1 lg:w-full text-sm rounded-sm border-primaryColor border lg:ml-3 ml-1 outline-none cursor-pointer'

        />
    )

    const handleEventSelectChange = (event) => {
        
        // const selectedEventText = event.target.options[event.target.selectedIndex].text;
        setSelectedEventType(event.target.value)
        updateEventSelectedOption(event.target.value);
    }

    const handleTimeSelectChange = (event) => {
        event.target.value === 'date_range' ? setDateRangeSelected(true) : setDateRangeSelected(false);
        setSelectedTimeType(event.target.value)
        setEventDateSelectedOption(event.target.value)
    }

    // const capitalizeString = (str) => {
    //     return str.charAt(0).toUpperCase() + str.slice(1)
    // }
    return (
        <div className='mt-10 w-full flex flex-wrap h-auto items-center justify-between px-2'>
            <div className='flex g:w-1/2 items-center'>
               
                    <span className='font-bold lg:block hidden'>Show me</span>
                    <div className='ml-3'>
                        <select id="small" className="block w-full py-1 lg:px-2 px-0 text-xs text-gray-900 border border-primaryColor rounded-sm bg-gray-50 outline-none" value={selectedEventType} onChange={handleEventSelectChange}>
                            <option  defaultValue="All Events">
                                All Events
                            </option>
                            <option value="Online Events">Online Events</option>
                            <option value="Offline Events">Offline Events</option>
                        </select>
                    </div>
                    <span className='ml-3 font-bold md:text-base text-xs'>on</span>
                    <div className='ml-3'>
                        <select id="small" className="block w-full py-1 lg:px-2 px-0 text-xs text-gray-900 border border-primaryColor rounded-sm bg-gray-50 outline-none" value={selectedTimeType} onChange={handleTimeSelectChange}>
                            <option  defaultValue="at_any_time">at any time</option>
                            <option value="today_event">today</option>
                            <option value="previous_event">previous events</option>
                            <option value="upcoming_event">upcoming events</option>
                            {/* <option value="date_range">date range</option> */}
                        </select>
                    </div>
         

                <div className='z-0'>
                    {isDateRangeSelected &&
                        <DatePicker
                            value={selectedDayRange}
                            onChange={setSelectedDayRange}
                            renderInput={renderCustomCalenderInput}
                            inputPlaceholder="Select a day range"
                            shouldHighlightWeekends
                            colorPrimary="#FF6D01"
                            colorPrimaryLight="rgba(255, 109, 1, 0.4)"
                            calendarClassName="myCustomCalendar"

                        />
                    }
                    </div>

            </div>
            <div className='text-xl font-semibold text-primaryColor lg:w-1/2 text-end mt-10 lg:mt-0'>
                {/* {selectedEventType} */}
                {eventSelectedOption}
            </div>
        </div>
    )
}

export default EventFilter
