import React from 'react';
import EventDetailCard from './EventDetailCard/EventDetailCard';
import { useCustomEvents } from '../../../context/eventContext';
import { compareDate } from '../../../services/dateFormatting';
import { motion } from 'framer-motion';
import { fadeUpAnimation as cardVariants } from '../../../data';

const filterEvents = (events, eventDateOption, eventCategoryOption) => {
  return events.filter(event => {
    const isCategoryMatch =
        (eventCategoryOption === 'Online Events' && event.event_online === true) ||
        (eventCategoryOption === 'Offline Events' && event.event_online === false) ||
        eventCategoryOption === 'All Events';

    const isDateMatch = compareDate(event.event_date) === eventDateOption || eventDateOption === 'at_any_time';

    return isCategoryMatch && isDateMatch;
  });
};

const EventsContainer = ({ events }) => {
  const { eventSelectedOption, eventDateSelectedOption } = useCustomEvents();

  const filteredEvents = filterEvents(events, eventDateSelectedOption, eventSelectedOption);

  const renderEventCards = () => {
    return (
        <div className='grid lg:grid-cols-3 grid-cols-1 gap-4 mt-10 px-1 lg:px-0'>
          {filteredEvents.length === 0 ? (
              <div className=" mt-10">
                No Matching Events Found
              </div>
          ) : (
              filteredEvents.reverse().map((event, index) => (
                  <motion.div key={index} initial="hidden" whileInView="visible" exit="exit">
                    <motion.div variants={cardVariants} transition={{ delay: 0.5 }}>
                      <EventDetailCard event={event} />
                    </motion.div>
                  </motion.div>
              ))
          )}
        </div>
    );
  };

  return renderEventCards();
};

export default EventsContainer;
