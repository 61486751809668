import React from 'react';
import { flexRender } from '@tanstack/react-table';
import TableNavigation from './TableNavigation';
import {Button, Select} from 'flowbite-react'
import {Link} from "react-router-dom";


const Table = ({ table, tableTitle, tableButtonLinks }) => {

    return (
        <div>
            <div className='flex justify-between mb-5'>

                <div className='text-primaryColor text-xl font-bold ' >{tableTitle}</div>
                <div className='flex'>
                    {
                        tableButtonLinks.map((tableButtonLink, index) => (
                            <Link to={tableButtonLink.buttonLink} className='mr-3'>
                                <Button color='light'>{tableButtonLink.buttonTitle}</Button>
                            </Link>
                        ))
                    }
                    <Select
                        value={table.getState().pagination.pageSize}
                        onChange={e => {
                            table.setPageSize(Number(e.target.value))
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize} books
                            </option>
                        ))}
                    </Select>

                </div>
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header, index) => (
                                <th key={index} scope='col' className='px-6 py-3'>
                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table?.getRowModel().rows && table?.getRowModel().rows.map(row => (
                        <tr key={row.id} className='bg-white border-b hover:bg-gray-50'>
                            {row.getVisibleCells().map((cell, index) => (
                                <td key={cell.id} scope='row'
                                    className={`px-6 py-4 ${index === 1 ? 'font-medium text-gray-900 whitespace-nowrap' : ''} ${index}`}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>

            </div>
            <TableNavigation
                pageIndex={table.getState().pagination.pageIndex}
                pageCount={table.getPageCount()}
                canPreviousPage={table.getCanPreviousPage()}
                canNextPage={table.getCanNextPage()}
                previousPage={table.previousPage}
                nextPage={table.nextPage}
            />
        </div>
    );
};

export default Table;