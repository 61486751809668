import { useQuery } from '@tanstack/react-query'
import React, {createContext, useContext } from 'react';

import axiosClient from '../services/network';

const BookContext = createContext();

export const BookContextProvider = ({children}) => {


    // for fetching api
    const fetchBooks = async () => {
        const response = await axiosClient.get("/books")
        return response.data.data
    }

  
    // react query book mutation

    const {data: books, isLoading, isError, error, isSuccess } = useQuery({ queryKey: ['books'], queryFn: fetchBooks})

    return (
        <BookContext.Provider value={{books, isLoading, isError, error, isSuccess}}>
            {children}
        </BookContext.Provider>
    )
}

export const useCustomBooks = () => useContext(BookContext)