import Root from "../Root";
import LibraryScreen from "../pages/LIbraryScreen/LibraryScreen";
import EventScreen from "../pages/EventScreen/EventScreen";
import EventDetailScreen from "../pages/EventScreen/EventDetailScreen"
import ProgramsScreen from "../pages/ProgramsScreen/ProgramsScreen"
import NotFoundScreen from "../pages/NotFound/NotFoundScreen";
import GalleryScreen from "../pages/GalleryScreen/GalleryScreen";
import PolicyScreen from "../pages/PolicyScreen/PolicyScreen";
import AdminHome from "../pages/admin/AdminHome";
import ProgramDetailScreen from "../pages/ProgramsScreen/ProgramDetailScreen";
import ContactUsScreen from "../pages/ContactUsScreen/ContactUsScreen";
import AdminEvent from "../pages/admin/admin-events/AdminEvent";
import AdminLogin from "../pages/admin/auth-components/AdminLogin";
import AdminCreateEvent from "../pages/admin/admin-events/AdminCreateEvent";
import AdminBooks from "../pages/admin/admin-books/AdminBooks";
import AdminCreateBooks from "../pages/admin/admin-books/AdminCreateBooks";
import AdminCreatePublisher from "../pages/admin/admin-books/AdminCreatePublisher";
import AdminPublishers from "../pages/admin/admin-books/AdminPublishers";


export const routes = [
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "/library",
    element: <LibraryScreen />,
  },
  {
    path: "/event",
    element: <EventScreen />,
  },
  {
    path: "/event/:id",
    element: <EventDetailScreen />
  },
  {
    path: "/programs",
    element: <ProgramsScreen />,
  },
  {
    path: "/programs/:name",
    element: <ProgramDetailScreen />,
  },
  {
    path: "/gallery",
    element: <GalleryScreen />,
  },
  {
    path: "/policies",
    element: <PolicyScreen />,
  },
  {
    path: "/contact",
    element: <ContactUsScreen />
  },
  // admin routes
  {
    path: "/mhs_admin",
    element: <AdminHome />
  },
  {
    path: "/mhs_admin/createEvent",
    element: <AdminCreateEvent />
  },
  {
    path: "/mhs_admin/createBook",
    element: <AdminCreateBooks />
  },
  {
    path: "/mhs_admin/createPublisher",
    element: <AdminCreatePublisher />
  },
  {
    path: "/mhs_admin/events",
    element: <AdminEvent />
  },
  {
    path: "/mhs_admin/books",
    element: <AdminBooks />
  },
  {
    path: "/mhs_admin/publishers",
    element: <AdminPublishers/>
  },
  {
    path: "/mhs_admin/login",
    element: <AdminLogin />
  },

  // admin routes end
  {
    path: "*",
    element: <NotFoundScreen />,
  },

];