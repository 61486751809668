import { useMutation, useQueryClient } from '@tanstack/react-query'
import axiosClient from '../services/network';

const useDeleteMutation = (resourceType, setOpenModal) => {
    const queryClient = useQueryClient();

    const deleteMutation = useMutation({
        mutationFn: async(resourceId) => {
            const response = await axiosClient.delete(`/${resourceType}/${resourceId}`);
            return response;
        },
        onSuccess: ({data: deletedResourceResponse}, variables, context) => {
            if(deletedResourceResponse.success) {
                setOpenModal(false);
                queryClient.invalidateQueries({queryKey: [resourceType]})
            }
        }
    })

    return deleteMutation;
}

export default useDeleteMutation;