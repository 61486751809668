// import Swiper core and required modules
import { Navigation } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import { IoCaretForwardCircle, IoCaretBackCircle } from 'react-icons/io5'
import { Pagination } from 'swiper/modules';

import { motion } from 'framer-motion'
import { slideInAnimation } from '../../data';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import TestimonialCard from '../Common/TestimonialCard';
import { useRef } from 'react';
import { studentTestimonials } from '../../data';

const Testimonials = () => {
    const swiperRef = useRef();
    return (
        <div className='container mx-auto mt-10 font-primary sm:px-5'>

            <motion.div
                initial="hidden"
                whileInView="visible"
                exit="exit"

            >
                <motion.div variants={slideInAnimation}>
            <div className='h-auto flex flex-col justify-center items-center'>
                <div className='text-sectionTitle font-semibold'>What <span className='text-primaryColor'>They Say</span> </div>
                <div className='text-paraText text-sm mt-3'>
                    Here are the most featured voice of our partners
                </div>

            </div>
            <div className="grid lg:grid-cols-8 grid-cols-1 gap-4 mt-10">
                <div className="lg:col-start-1 md:col-span-10 flex justify-center">
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={2}
                        breakpoints={{
                            300: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 1,
                                spaceBetween: 50,
                            },
                        }}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        modules={[Navigation]}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        loop={true}
                    >
                        {studentTestimonials.map((studentTestimonial, index) => (
                            <Swiper
                                key={index}
                                slidesPerView={'auto'}
                                centeredSlides={true}
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                            >
                                <SwiperSlide className='flex justify-center items-center' key={index}>
                                    <TestimonialCard studentName={studentTestimonial.studentName} position={studentTestimonial.position && studentTestimonial.position} fieldName={studentTestimonial.fieldName && studentTestimonial.fieldName} message={studentTestimonial.message} imageUrl={studentTestimonial.imageUrl} />
                                </SwiperSlide>



                            </Swiper>

                        ))}


                    </Swiper>

                </div>


            </div>

            <div className='flex justify-center w-auto'>
                <button onClick={() => swiperRef.current?.slidePrev()}>
                    <IoCaretBackCircle color='#272E3B' fontSize="2.5rem" />
                </button>
                <button onClick={() => swiperRef.current?.slideNext()}>
                    <IoCaretForwardCircle color='#272E3B' fontSize="2.5rem" />
                </button>
            </div>
            </motion.div>
            </motion.div>

        </div>
    )
}

export default Testimonials
