import { useState, useEffect } from 'react';

const useScrollLock = () => {
    const bodyStyle = document.body.style
    const [isLocked, setIsLocked] = useState(bodyStyle.overflowY === 'hidden')
    useEffect(() => {
        bodyStyle.overflowY = isLocked ? 'hidden' : 'auto'
    }, [isLocked, bodyStyle])
    
    const toggle = (value) => {
        setIsLocked(value)
        console.log(value)
    }
    
    return [isLocked, toggle]
}

export default useScrollLock