import { useMutation } from '@tanstack/react-query'
import React, { useContext } from 'react'
import axiosClient from '../../../services/network'
import { redirect } from 'react-router-dom'
import AuthContext from '../../../context/authContext'
import { Button } from 'flowbite-react'
import { HiLogout } from 'react-icons/hi'


const AdminLogout = () => {
    const { setAuthUser } = useContext(AuthContext);

    const mutation = useMutation({
        mutationFn: async () => {
            const logoutResponse = await axiosClient.post('/logout');
            console.log(logoutResponse)
            return logoutResponse;
        },
        onSuccess: ({data: response}, variables, context) => {
            if(response === 'success') {
                setAuthUser(false);
                return redirect('/mhs_admin/login')
            }
        }
    })

    const onSubmitLogout = () => {
            mutation.mutate();
            // console.log('logout')
        }
  return (
      <div>
      <Button color='dark' onClick={() => onSubmitLogout()}>Logout
        <HiLogout className='ml-2 h-5 w-5'/>
      </Button>
    </div>
  )
}

export default AdminLogout
