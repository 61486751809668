import React from 'react';
import { Alert } from 'flowbite-react';

const AlertComponent = ({ color, onDismiss, children }) => {
    return (
        <div className="mx-5">
            <Alert color={color} onDismiss={onDismiss}>
                {children}
            </Alert>
        </div>
    );
};

export default AlertComponent;
