import React, { useState } from 'react'
import { IoTrashOutline, IoPencilSharp } from 'react-icons/io5'
import { Tooltip } from 'flowbite-react';
import { useDeleteMutation } from '../../../mutations';
import DeleteModal from './DeleteModal';



const BookActionButtons = ({ bookOriginalId }) => {
    const [openModal, setOpenModal] = useState(false);

    const bookDeleteMutation = useDeleteMutation("books", setOpenModal)

    const handleBookDelete = () => {
        bookDeleteMutation.mutate(bookOriginalId);
    }
    return (
        <>
            <div className='flex justify-center'>
                <Tooltip content="edit" arrow={false}>
                    <button type="button" data-modal-target="popup-modal" data-modal-toggle="popup-modal" className="text-white bg-blue-400 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 ">
                        <IoPencilSharp />
                        <span className="sr-only">Icon description</span>
                    </button>
                </Tooltip>

               

                <Tooltip content="delete" arrow={false}>
                    <button type="button" onClick={() => setOpenModal(true)} className="text-white bg-red-400 hover:bg-red-500 transition duration-150 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2">
                        <IoTrashOutline />
                        <span className="sr-only">Icon description</span>
                    </button>
                </Tooltip>


                {/* confirm book delete model */}
               <DeleteModal 
                    show={openModal}
                    onClose={() => setOpenModal(false)}
                    onConfirm={() => handleBookDelete()}
                    isPending={bookDeleteMutation.isPending}
                    resourceName={"books"}
               />
            </div>
        </>

    )
}

export default BookActionButtons
