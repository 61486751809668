import React from 'react'

const Button = ({title, black = false}) => {
  return (

    
    <button className={`${black? 'bg-blackColor' : 'bg-primaryColor'} text-white px-6 py-2 rounded-full md:text-base text-xs`}>
        {title}
    </button>
  )
}

export default Button
