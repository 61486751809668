import React, { useContext } from 'react'
import Master from '../layout/Master';
import Table from '../../../components/Common/Table'
import { useReactTable, getCoreRowModel, getPaginationRowModel } from '@tanstack/react-table';
import AuthContext from '../../../context/authContext';
import AdminNotAuth from '../AdminNotAuth';
import { BookContextProvider, useCustomBooks } from '../../../context/bookContext';
import BookActionButtons from '../admin-components/BookActionButton';
import LoadingIndicator from '../../../components/Common/LoadingIndicator';

const AdminBooks = () => {
    return (
        <BookContextProvider>
            <AdminBooksContent />
        </BookContextProvider>
    )
}
const AdminBooksContent = () => {
    const { authUser } = useContext(AuthContext);
    const { books, isLoading, isSuccess } = useCustomBooks();

    const columns = [
        {
            header: 'Id',
            cell: ({ row }) => {
                return Number(row.id) + 1;
            }
        },
        {
            header: "Book Name",
            accessorKey: "book_name",
            footer: "Book Name"
        },
        {
            header: "Author",
            accessorKey: "book_author",
            footer: "Author"
        },
        {
            header: "Publisher",
            accessorKey: "publisher.name",
            footer: "Publisher",
            cell: ({row}) => {
                const publisher = row.original.publisher;
                return <div>{publisher ? publisher.name : 'deleted'}</div>
            }
        },
        {
            header: "Actions",
            cell: ({ row }) => {
                const book_original_id = row.original._id;
                return <BookActionButtons bookOriginalId={book_original_id} />;
            }
        }
    ]

    const bookTable = useReactTable({
        data: books, columns,
        // pipeline
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        debugTable: true,
    })
    const bookTableButtonLinks = [
        {
            buttonTitle : "New Book",
            buttonLink: "/mhs_admin/createBook"
        },
        {
            buttonTitle: "New Publisher",
            buttonLink: "/mhs_admin/createPublisher"
        }
    ]

    return (
        <Master>
            {!authUser && <AdminNotAuth />}
            {isLoading &&
                <div className='flex justify-center items-center w-full h-screen'>
                    <LoadingIndicator />
                </div>
            }
            {
                authUser && !isLoading &&
                <div className='my-10'>
                  <Table
                      table={bookTable}
                      tableTitle={"Books"}
                      tableButtonLinks={bookTableButtonLinks}
                  />
                </div>
            }
        </Master>
    )
}

export default AdminBooks
