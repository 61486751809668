import axiosClient from "./network";
import FileSaver from 'file-saver'

export const savePhoto = (tempImageSource) => {
    FileSaver.saveAs(tempImageSource, `myanmarhealthsupport${getRandomInt(1, 1000)}`)
}

export const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
}

export const fetchImages = async () => {
    try {
        return axiosClient.get('/images').then(response =>
            response.data.data
        );
    } catch (e) {

    }


}