import React from "react";
import VideoCard from "../Common/VideoCard";
import EventCardContainer from "../Common/Event/EventCardContainer";
import { motion } from "framer-motion";
import { slideInAnimation as cardVariants } from "../../data/animationVariants";

const SocialConnect = () => {
  return (
    <motion.div initial="hidden" whileInView="visible" exit="exit">
      <motion.div variants={cardVariants} transition={{ delay: 0.5 }}>
        <div className="container mx-auto grid md:grid-cols-3 grid-cols-1 gap-6 mt-10 font-primary sm:px-5">
          <div className="col-span-2">
            <div className="w-full md:px-0 px-5">
              <div className="text-sectionTitle font-semibold">
                Recent <span className="text-primaryColor">Posts</span>{" "}
              </div>
              <div className="text-paraText text-sm mt-3">
                Teaching and teaching that will be able to use the hands to work
                and live.
              </div>
              <div className='grid md:grid-cols-2 grid-cols-1 gap-2 mt-5'>
                <VideoCard videoTitle="ကျန်းမာရေးအထောက်အကူပြုအစီအစဉ်များ" videoContent="လွတ်လပ်ရေးနေ့ ကလေးများ ပျော်ရွှင်စွာကစားနေမှု မြင်ကွင်းများ" videoUrl="https://myanmarhealthsupport.s3.ap-southeast-1.amazonaws.com/mhs-myanmar2.mp4" />
                <VideoCard videoTitle="MHS ၏ မျှော်မှန်းချက်နှင့် ရည်မှန်းချက်" videoContent="MHS တာဝန်ခံ၏ ပြောကြားချက်" videoUrl="https://res.cloudinary.com/myanmar-fixie-nation/video/upload/v1703431015/Myanmar%20Health%20Support/Videos/MHS_1_1_l0u9ik.mp4" />
              </div>
            </div>
          </div>
          <div className="col-span-1">
            <div className="w-full md:px-0 pl-5 pr-0">
              <div className="text-sectionTitle font-semibold">
                Upcoming <span className="text-primaryColor">Events</span>{" "}
              </div>
              <div className="text-paraText text-sm mt-3 mb-5">
                Bringing People Together
              </div>
              <EventCardContainer />
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default SocialConnect;
