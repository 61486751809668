import React from 'react'
import { IoAlertCircleOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { availableDate } from '../../../../services/dateFormatting'

const EventDetailCardFooter = ({ event }) => {
  return (
    <div className='flex justify-start'>
      <a
        href={event.online_event_access}
        className={`${availableDate(new Date(event.event_date)) ? 'bg-primaryColor' : 'bg-blackColor cursor-auto'} inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-md focus:outline-none`}
      >
        Register Event
        <svg
          className="w-3.5 h-3.5 ml-2"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 10"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M1 5h12m0 0L9 1m4 4L9 9"
          />
        </svg>
      </a>
      <Link to={`/event/${event._id}`}>
        <button
          className="ml-1 inline-flex items-center px-3 py-2 text-lg font-medium text-center text-priamryColor border border-primaryColor bg-white rounded-md focus:outline-none"
        >
          <IoAlertCircleOutline />
        </button>
      </Link>
    </div>
  )
}

export default EventDetailCardFooter
