import React, { useContext } from 'react'
import AuthContext from '../../../context/authContext';
import Master from '../layout/Master';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';
import { EventContextProvider, useCustomEvents } from '../../../context/eventContext';
import LoadingIndicator from '../../../components/Common/LoadingIndicator';
import StatusBadge from '../admin-components/StatusBadge';
import ActionButtons from '../admin-components/ActionButtons';
import AdminNotAuth from '../AdminNotAuth';
import Table from '../../../components/Common/Table'
import { Button, Select } from 'flowbite-react'
import { Link } from 'react-router-dom';

const AdminEvent = () => {
  return (
    <EventContextProvider>
      <AdminEventContent />
    </EventContextProvider>
  )
}

const AdminEventContent = () => {
  const { authUser } = useContext(AuthContext);
  const { events, isLoading } = useCustomEvents();

  /** @type import('@tanstack/react-table').ColumnDef<any> */
  const columns = [
    {
      header: 'Id',
      cell: ({ row }) => {
        return Number(row.id) + 1;
      }
    },
    {
      header: "Event Title",
      accessorKey: "event_title",
      footer: "Event Title"
    },
    {
      header: "Description",
      accessorKey: "event_description",
      cell: ({ row }) => {
        const event_description = row.original.event_description;
        if (!event_description) return 'error';
        return event_description.substring(0, 50)
      },
      footer: "Description"
    },
    {
      header: "Location",
      accessorKey: "event_location",
      footer: "Location"
    },
    {
      header: "Time ( MM )",
      accessorKey: "event_time",
      footer: "Time"
    },
    {
      header: "Online",
      accessorKey: "event_online",
      cell: ({ row }) => {
        const event_online_status = row.original.event_online;
        return <StatusBadge status={event_online_status} />
      },
      footer: "Online"
    },
    {
      header: "Actions",
      cell: ({ row }) => {
        const event_original_id = row.original._id;
        return <ActionButtons eventOriginalId={event_original_id} />;
      }
    }
  ]
  const eventTable = useReactTable({ data: events, columns, getCoreRowModel: getCoreRowModel() })
  const eventButtonLinks = [
    {
      buttonTitle : "New Event",
      buttonLink: "/mhs_admin/createEvent"
    }
  ]

  return (
      <Master>
        {isLoading &&
            <div className='flex justify-center items-center w-full h-screen'>
              <LoadingIndicator />
            </div>
        }
        {authUser && !isLoading && events.length !== 0 && (
            <div className='mt-10'>
              <Table table={eventTable} tableButtonLinks={eventButtonLinks} tableTitle={"Events"}/>
            </div>
        )}
        {authUser && !isLoading && events.length === 0 && (
            <div className='flex justify-center mt-20'>
              <div>No Events Yet</div>
            </div>
        )}
        {!authUser &&
            <AdminNotAuth />
        }
      </Master>
  )
}

export default AdminEvent
