import React, { useState } from 'react'
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel, getFilteredRowModel } from '@tanstack/react-table';
import Layout from '../../components/Layout/Layout'
import ScreenHeader from '../../components/Common/ScreenHeader'
import Footer from '../../components/HomePage/Footer'
import ScrollToTop from '../../services/scrollToTop'
import CustomHelmet from '../../components/Common/CustomHelmet'
import { BookContextProvider, useCustomBooks } from '../../context/bookContext'
import LoadingIndicator from "../../components/Common/LoadingIndicator"
import { useDebounce } from 'use-debounce'


const LibraryScreen = () => {
    return (
        <BookContextProvider>
            <LibraryScreenContent />
        </BookContextProvider>
    )
}

const LibraryScreenContent = () => {
    const { books, isLoading, isError, error, isSuccess } = useCustomBooks();
    const [filtering, setFiltering] = useState("");
    const [debouncedText] = useDebounce(filtering, 500);

    const columns = [
        {
            header: 'Id',
            cell: ({ row }) => {
                return Number(row.id) + 1;
            }
        },
        {
            header: "Book Name",
            accessorKey: "book_name",
            footer: "Book Name"
        },
        {
            header: "Author",
            accessorKey: "book_author",
            footer: "Author"
        },
        {
            header: "Publisher",
            accessorKey: "publisher.name",
            footer: "Publisher",
            cell: ({ row }) => {
                const publisher = row.original.publisher;
                return <div>{publisher ? publisher.name : 'N/A'}</div>
            },
          
        }
    ]

    const bookDisplayTable = useReactTable({
        data: books, columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        debugTable: true,
        state: {
            globalFilter: debouncedText
        },
        onGlobalFilterChange: setFiltering
    })



    return (
        <Layout>
            <CustomHelmet pageTitle="Library" />
            <ScrollToTop />
            <ScreenHeader screenName="Library" />
            <div className='mt-10 container mx-auto h-auto font-primary sm:px-5'>
                <div className='h-auto flex flex-col justify-center items-center mb-10'>
                    <div className='text-sectionTitle font-semibold'>
                        Our <span className='text-primaryColor'>Library</span>
                    </div>
                    <div className='text-paraText text-sm mt-3'>
                        Here are the most featured voice of our students
                    </div>

                </div>

                <div className="grid md:grid-cols-6 gap-4 my-10">
                    <div className="md:col-start-2 md:col-span-4 md:mx-0 mx-5">
                        <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                        <div className="relative w-full">
                            <input type="search" id="default-search" className="outline-none block w-full p-4 pl-5 text-sm text-gray-900 border border-gray-300 rounded-full bg-gray-50" placeholder="Search Book" onChange={(e) => setFiltering(e.target.value)} />
                            {filtering === '' &&
                                <div className="absolute inset-y-0 right-5 flex items-center pointer-events-none">
                                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 mt-12'>
                    {isLoading &&
                        <div className='flex justify-center mt-20'>
                            <LoadingIndicator />
                        </div>}
                    {isError && <div>{error.message}</div>}
                   
                </div> 
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">

                            {
                                bookDisplayTable.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header, index) => (
                                            <th key={index} scope='col' className='px-6 py-3'>
                                                {
                                                    flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )
                                                }
                                            </th>
                                        ))}
                                    </tr>
                                ))
                            }
                        </thead>
                        <tbody>
                            {isSuccess &&
                                bookDisplayTable?.getRowModel().rows && bookDisplayTable?.getRowModel().rows.map(row => (
                                    <tr key={row.id} className='bg-white border-b hover:bg-gray-50'>
                                        {row.getVisibleCells().map((cell, index) => (
                                            <td key={cell.id} scope='row' className={`px-6 py-4 ${index === 1 ? 'font-medium text-gray-900 whitespace-nowrap' : ''} ${index}`}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
                {isSuccess &&
                    <nav
                        className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4"
                        aria-label="Table navigation"
                    >
                        <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                            Showing{" "}
                            <span className="font-semibold text-gray-900 dark:text-white">{bookDisplayTable.getState().pagination.pageIndex + 1}</span> of{" "}
                            <span className="font-semibold text-gray-900 dark:text-white">{bookDisplayTable.getPageCount()}</span>
                        </span>
                        <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                            <li>
                                <button
                                    onClick={() => bookDisplayTable.previousPage()}
                                    disabled={!bookDisplayTable.getCanPreviousPage()}
                                    className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                >
                                    Previous
                                </button>
                            </li>

                            {bookDisplayTable.getState().pagination.pageIndex > 0 &&
                                <li>
                                    <button
                                        onClick={() => bookDisplayTable.setPageIndex(bookDisplayTable.getState().pagination.pageIndex - 1)}
                                        className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                    >
                                        {bookDisplayTable.getState().pagination.pageIndex}

                                    </button>
                                </li>
                            }
                            <li>
                                <span
                                    aria-current="page"
                                    className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                                >
                                    {bookDisplayTable.getState().pagination.pageIndex + 1}
                                </span>
                            </li>
                            <li>
                                <button
                                    onClick={() => bookDisplayTable.setPageIndex(bookDisplayTable.getState().pagination.pageIndex + 1)}
                                    className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                >
                                    {bookDisplayTable.getState().pagination.pageIndex + 2}

                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => bookDisplayTable.setPageIndex(bookDisplayTable.getState().pagination.pageIndex + 2)}
                                    className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                >
                                    {bookDisplayTable.getState().pagination.pageIndex + 3}
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => bookDisplayTable.nextPage()}
                                    disabled={!bookDisplayTable.getCanNextPage()}
                                    className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                >
                                    Next
                                </button>
                            </li>
                        </ul>
                    </nav>
                }


            </div>
            <Footer />
        </Layout>
    )
}

export default LibraryScreen
