import React from 'react'


const ProgramCard = ({children, title, content}) => {
    return (
        <div className="block w-full p-6 border hover:shadow-md transition duration-300 rounded-lg cursor-pointer bg-cardBackgroundColor h-60">
            <div className='flex justify-center flex-col items-center'>
                {children}
            <div className='text-lg text-center font-semibold mt-2 mb-1'>{title}</div>

                <p className="text-xs text-paraText leading-loose text-center">{content.substring(0, 220)}</p>
            </div>
        </div>
    )
}

export default ProgramCard
