import React from 'react'
import { motion  } from 'framer-motion'
import { IoPaperPlaneOutline, IoEyeOutline } from 'react-icons/io5'
import { slideInAnimation as cardVariants } from '../../data'

const VMission = () => {

    return (
        <motion.div
            initial="hidden"
            whileInView="visible"

        >
            <motion.div variants={cardVariants}>
                <div className='container mx-auto grid md:grid-cols-2 grid-cols-1 gap-4 mt-10 sm:px-5 font-primary h-auto'>
                    <div className='w-full md:px-0 px-5'>
                        <div className='text-sectionTitle font-semibold'>Our <span className='text-primaryColor'>Mission</span> </div>
                        <div className='w-full bg-cardBackgroundColor rounded-lg px-5 py-10 mt-5 flex items-center'>
                            <div className='bg-primaryColor w-20 h-20 rounded-lg flex flex-none items-center justify-center'>
                                <IoPaperPlaneOutline fontSize="2rem" color='#ffffff' />
                            </div>
                            <div className='ml-5 text-sm text-paraText'>The mission of MHS is research; To improve the health awareness of migrant students by raising awareness of basic health in community engagement and services and raising them to be healthy and fit individuals. </div>
                        </div>
                    </div>
                    <div className='h-auto w-full md:px-0 px-5 md:mt-0 mt-10'>
                        <div className='text-sectionTitle font-semibold'>Our <span className='text-primaryColor'>Vission</span> </div>
                        <div className='w-full bg-cardBackgroundColor rounded-lg px-5 py-10 mt-5 flex items-center'>
                            <div className='bg-primaryColor w-20 h-20 rounded-lg flex flex-none items-center justify-center'>
                                <IoEyeOutline fontSize="2rem" color='#ffffff' />
                            </div>
                            <div className='ml-5 text-sm text-paraText'>Our vision is a healthy society where everyone can live happily to their full human potential.</div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </motion.div>

    )
}

export default VMission
