import { IoFitnessOutline, IoLibraryOutline, IoFootballOutline, IoHomeOutline, IoAccessibilityOutline, IoEarthOutline, IoBookOutline, IoExtensionPuzzleOutline, IoNavigateOutline, IoChatboxEllipsesOutline, IoCallOutline } from "react-icons/io5";

export const getIcon = (iconType) => {
    switch (iconType) {
        case 'physical':
            return <IoAccessibilityOutline color="#FF6D01" fontSize="2.5rem" />;
        case 'fitness':
            return <IoFitnessOutline color='#FF6D01' fontSize="2.5rem" />;
        case 'library':
            return <IoBookOutline color='#FF6D01' fontSize="2.5rem" />;
        case 'research':
            return <IoLibraryOutline color='#FF6D01' fontSize="2.5rem" />
        case 'football':
            return <IoFootballOutline color='#FF6D01' fontSize="2.5rem" />;
        case 'warm':
            return <IoHomeOutline color="#FF6D01" fontSize="2.5rem" />;
        case 'public':
            return <IoEarthOutline color="#FF6D01" fontSize="2.5rem" />;
        case 'vocational':
            return <IoExtensionPuzzleOutline color="#FF6D01" fontSize="2.5rem" />;
        case 'navigation':
            return <IoNavigateOutline color="#ffffff" fontSize="1.5rem" />;
        case 'email':
            return <IoChatboxEllipsesOutline color="#ffffff" fontSize="1.5rem" />;
        case 'phone':
            return <IoCallOutline color="#ffffff" fontSize="1.5rem" />;
        default:
            return <></>
    }
}