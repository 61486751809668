import React, { useContext, useState, useEffect } from 'react'
import AuthContext from '../../../context/authContext'
import AdminLogout from '../auth-components/AdminLogout';
import { Link } from 'react-router-dom';
import { Button, Avatar, Dropdown } from 'flowbite-react';
import { IMAGES } from '../../../constants';
import {
  HiOutlineHome,
  HiOutlineGlobeAlt
} from "react-icons/hi";



const Master = (props) => {
  const { authUser } = useContext(AuthContext);

  const [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    const newPage = window.location.pathname;
    setCurrentPage(newPage);
  }, [setCurrentPage]);

  return (
    <div className='container mx-auto mt-10'>
      {currentPage !== "/mhs_admin/login" &&
        <div className='w-full  h-auto py-5 px-10 text-lg rounded-lg flex justify-between'>
          <div className='font-bold'>MHS Admin</div>
          <div>
            {
              authUser && <div className='flex justify-between items-center'>
                <Link to={"/"} className='mr-2'>
                  <Button color='light'>
                    <HiOutlineGlobeAlt className='mr-2' />
                    Portal
                  </Button>
                </Link>
                <Link to={"/mhs_admin"} className='mr-2'>
                  <Button color={currentPage === '/mhs_admin' ? 'dark' : 'light'}>
                    <HiOutlineHome className='mr-2' />
                    Home
                  </Button>
                </Link>
                <Link to={"/mhs_admin/events"} className='mr-2'>
                  <Button color={currentPage === '/mhs_admin/events' ? 'dark' : 'light' }>Events</Button>
                </Link>
                <Link to={"/mhs_admin/books"} className='mr-2'>
                  <Button  color={currentPage === '/mhs_admin/books' ? 'dark' : 'light'}>Books</Button>
                </Link>
                <Link to={"/mhs_admin/publishers"} className='mr-2'>
                  <Button  color={currentPage === '/mhs_admin/publishers' ? 'dark' : 'light'}>Publishers</Button>
                </Link>
                <Link to={"/mhs_admin/events"} className='mr-2'>
                  <Button color='light'>Admins</Button>
                </Link>

                <Dropdown
                  label={<Avatar img={IMAGES.admin_profile_image} rounded status='online' statusPosition='bottom-right'>
                    <div className="space-y-1 font-medium dark:text-white text-start">
                      <div className='text-sm'>{authUser && authUser.name}</div>
                      <div className="text-xs text-gray-500 dark:text-gray-400">Admin</div>
                    </div>
                  </Avatar>}
                  arrowIcon={false}
                  inline
                >
                  <Dropdown.Header>
                    <span className="block text-sm">{authUser && authUser.name}</span>
                    <span className="block truncate text-sm font-medium">kyaw@gmail.com</span>
                  </Dropdown.Header>
                  <Dropdown.Item>Dashboard</Dropdown.Item>
                  <Dropdown.Item>Settings</Dropdown.Item>
                  <Dropdown.Item>Earnings</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item>
                    <AdminLogout />
                  </Dropdown.Item>
                </Dropdown>

              </div>
            }
            {
              !authUser &&
              <Link to={"/mhs_admin/login"}>
                <Button>Login</Button>
              </Link>
            }
          </div>
        </div>
      }
      <div>
        {props.children}
      </div>
    </div>
    // <>
    //   <Navbar fluid>
    //     <div className="w-full p-3 lg:px-5 lg:pl-3">
    //       <div className="flex items-center justify-between">
    //         <div className="flex items-center">
    //           <Navbar.Brand href="/">
    //             <img alt="" src="/images/logo.svg" className="mr-3 h-6 sm:h-8" />
    //             <span className="self-center whitespace-nowrap text-2xl font-semibold dark:text-white">
    //               MHS Admin
    //             </span>
    //           </Navbar.Brand>
    //         </div>
    //         <div className="flex items-center gap-3">
    //           {authUser &&
    //             <Dropdown
    //               arrowIcon={false}
    //               inline
    //               label={
    //                 <Avatar alt="User settings" img={IMAGES.admin_profile_image} rounded />
    //               }
    //             >
    //               <Dropdown.Header>
    //                 <span className="block text-sm"></span>
    //                 <span className="block truncate text-sm font-medium">{authUser.name}</span>
    //               </Dropdown.Header>
    //               <Dropdown.Item>Dashboard</Dropdown.Item>
    //               <Dropdown.Item>Settings</Dropdown.Item>
    //               <Dropdown.Item>Earnings</Dropdown.Item>
    //               <Dropdown.Divider />
    //               <Dropdown.Item>Sign out</Dropdown.Item>
    //             </Dropdown>
    //           }
    //           {authUser &&
    //             <Navbar.Toggle />
    //           }
    //         </div>
    //       </div>
    //     </div>
    //   </Navbar>

    //   <div className='flex items-start'>
    //     <Sidebar aria-label="Sidebar with multi-level dropdown example">
    //       <div className="flex h-full flex-col justify-between py-2">
    //         <div>
    //           <form className="pb-3 md:hidden">
    //             <TextInput
    //               icon={HiSearch}
    //               type="search"
    //               placeholder="Search"
    //               required
    //               size={32}
    //             />
    //           </form>
    //           <Sidebar.Items>
    //             <Sidebar.ItemGroup>
    //               {authUser &&
    //                 <Link to={"/mhs_admin"}>
    //                   <Sidebar.Item
    //                     icon={HiChartPie}
    //                     className={
    //                       "/mhs_admin" === currentPage ? "bg-gray-100 dark:bg-gray-700" : ""
    //                     }
    //                   >
    //                     Dashboard
    //                   </Sidebar.Item>
    //                 </Link>
    //               }

    //               {authUser &&
    //                 <Link to={"/mhs_admin/events"}>
    //                   <Sidebar.Item
    //                     icon={HiChartPie}
    //                     className={
    //                       "/mhs_admin/events" === currentPage ? "bg-gray-100 dark:bg-gray-700" : ""
    //                     }
    //                   >
    //                     Events
    //                   </Sidebar.Item>
    //                 </Link>
    //               }
    //                {authUser &&
    //                 <Link to={"/mhs_admin/books"}>
    //                   <Sidebar.Item
    //                     icon={HiChartPie}
    //                     className={
    //                       "/mhs_admin/books" === currentPage ? "bg-gray-100 dark:bg-gray-700" : ""
    //                     }
    //                   >
    //                     Books
    //                   </Sidebar.Item>
    //                 </Link>
    //               }

    //               <Sidebar.Item href="/authentication/sign-in" icon={HiLogin}>
    //                 Sign in
    //               </Sidebar.Item>
    //               <Sidebar.Item href="/authentication/sign-up" icon={HiPencil}>
    //                 Sign up
    //               </Sidebar.Item>
    //             </Sidebar.ItemGroup>
    //             <Sidebar.ItemGroup>
    //               <Sidebar.Item
    //                 href="https://github.com/themesberg/flowbite-react/"
    //                 icon={HiClipboard}
    //               >
    //                 Docs
    //               </Sidebar.Item>
    //               <Sidebar.Item
    //                 href="https://flowbite-react.com/"
    //                 icon={HiCollection}
    //               >
    //                 Components
    //               </Sidebar.Item>
    //               <Sidebar.Item
    //                 href="https://github.com/themesberg/flowbite-react/issues"
    //                 icon={HiInformationCircle}
    //               >
    //                 Help
    //               </Sidebar.Item>
    //             </Sidebar.ItemGroup>
    //           </Sidebar.Items>
    //         </div>
    //       </div>
    //     </Sidebar>
    //     <div className='ml-5'>
    //     {props.children}

    //     </div>
    //   </div>
    // </>
  )
}

export default Master
