import React from 'react'
import Layout from '../../components/Layout/Layout'
import ScreenHeader from '../../components/Common/ScreenHeader'
import ScrollToTop from '../../services/scrollToTop'
import PolicyContent from '../../components/Common/Policy/PolicyContent'
import { policies } from '../../data/allPolicies'
import Footer from '../../components/HomePage/Footer'
import CustomHelmet from '../../components/Common/CustomHelmet'

const PolicyScreen = () => {
  return (
    <Layout>
      <CustomHelmet pageTitle="Policy"/>
      <ScrollToTop />
      <ScreenHeader screenName="Policy" />
      <div className='mt-10 container mx-auto sm:px-5 font-primary'>
        {policies.map((policy, index) => (
          <PolicyContent titleText={policy.titleText} contentText={policy.contentText} />
        ))}
      </div>
      <Footer />
    </Layout>
  )
}

export default PolicyScreen
