import React from 'react'
import StatisticCard from '../Common/StatisticCard';
import { motion } from 'framer-motion'
const Statistic = () => {


  return (
    <div className='bg-Statistic bg-cover bg-center bg-blend-normal md:h-72 h-auto md:py-0 py-10 mt-10'>
      <div className="container mx-auto h-full md:px-16 px-10">
        <div className='flex md:flex-row flex-col justify-around h-full'>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}

          >
            <StatisticCard count={1000} icon='book-outline' title='Books' />
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}

          >

            <StatisticCard count={2000} icon='school-outline' title='Students' />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}

          >
            <StatisticCard count={20} icon='people-outline' title='Volunteers' />
          </motion.div>


          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}

          >
            <StatisticCard count={0} icon='reader-outline' title='Research Papers' />
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default Statistic;
