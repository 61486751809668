import React from 'react'
import CountUp from 'react-countup';
import { IoBookOutline, IoSchoolOutline, IoPeopleOutline, IoReaderOutline } from "react-icons/io5";


const StatisticCard = ({count,icon,title}) => {

    const getIcon = (iconType) => {
        switch (iconType) {
            case 'book-outline':
                return <IoBookOutline fontSize="3rem" color='#ffffff'/>;
            case 'school-outline':
                return <IoSchoolOutline fontSize="3rem" color='#ffffff'/>;
            case 'people-outline':
                return <IoPeopleOutline fontSize="3rem" color='#ffffff' />;
            case 'reader-outline':
                return <IoReaderOutline fontSize="3rem" color='#ffffff'  />;
            default: 
            return <></>
        }
    }
    return (
        <div className='h-full w-full flex flex-col justify-center items-center md:mb-0 mb-10'>
            {getIcon(icon)}
            <div className='text-3xl text-primaryColor font-extrabold mt-3'>
                <CountUp end={count} />
            </div>
            <div className='text-xl text-white mt-1'>
                {title}
            </div>
        </div>
    )
}

export default StatisticCard
