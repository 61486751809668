import React from 'react'
import { Helmet } from 'react-helmet'

const CustomHelmet = ({ pageTitle }) => {
    return (
        <Helmet>
            <title>{pageTitle} - Myanmar Health Support</title>
            <meta name="description" content={`${pageTitle} - Myanmar Health Support`} />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta property="og:url" content="https://myanmarhealthsupport.org" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Myanmar Health Support" />
            <meta
                property="og:description"
                content="Our MHS team has been trying to support the basic health, physical and mental fitness "
            />
            <meta
                property="og:image"
                content="https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124666/Myanmar%20Health%20Support/programs/physical%20programs/physical-training-2_yvvpdb.jpg"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="myanmarhealthsupport.org" />
            <meta property="twitter:url" content="https://myanmarhealthsupport.org" />
            <meta name="twitter:title" content="Myanmar Health Support" />
            <meta
                name="twitter:description"
                content="Our MHS team has been trying to support the basic health, physical and mental fitness "
            />
            <meta
                name="twitter:image"
                content="https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124666/Myanmar%20Health%20Support/programs/physical%20programs/physical-training-2_yvvpdb.jpg"
            />
        </Helmet>
    )
}

export default CustomHelmet
