export const policies = [
    {
        titleText: 'MHS Sexual Harassment Policy',
        contentText: `စက်တင်ဘာ ၆၊ ၂၀၂၃ ခုနှစ်။
              \n ၁။ MHS တွင်စေတနာ့ဝန်ထမ်းအဖြစ် တာဝန်ထမ်းဆောင်နေသူများအနေဖြင့် မည်သူ့ကိုမဆို ကာယိန္ဒြေ ပျက်စီးစေရန် ကြံစည်အားထုတ်ကြိုးပမ်းခြင်းနှင့် လိင်ပိုင်းဆိုင်ရာကျူးလွန်ခြင်းများရှိသည်ဟု ခိုင်လုံသည့် အထောက်အထားများတွေ့ရှိပါက ခုခံချေပခွင့်မရှိ MHS Sexual Harassment Policy ဖြင့် အရေးယူမည် ဖြစ်သလို အဆိုပါကျူးလွန်သူကို MHS Facebook Page တွင် တရားဝင်ထုတ်ပြန်အသိပေးသွားမည် ဖြစ် သည်။
              \n ၂။ MHS က မည်သူမဆို စေတနာ့ဝန်ထမ်းအချင်းချင်း သို့တည်းမဟုတ် လုပ်ငန်းခွင်အတွင်းနှင့် မိတ်ဘက် အဖွဲ့အစည်းများက လူပုဂ္ဂိုလ်များအပေါ် ကာယိန္ဒြေပျက်စီးစေရန် ကြံစည်အားထုတ်ကြိုးပမ်းခြင်းနှင့် လိင်ပိုင်းဆိုင်ရာကျူးလွန်ခြင်းများအပေါ် ကန့်သတ်တားမြစ်ထားသလို MHS စေတနာ့ဝန်ထမ်းများ အပေါ် အခြားမည်သူမဆို ကြံစည်အားထုတ်ကြိုးပမ်းခဲ့မည်ဆိုပါကလည်း တရားဝင် ထုတ်ပြန်ကြေညာမည်ဖြစ်သည်။
              `
    },
    {
        titleText: `Management & Administrative Policies for MHS:
        Personnel, Communications, Procurement and
        Finance Policies`,
        contentText: `MHS is an organized institution that not only delivers services but also manages staff personnel, oversees administration and maintains cash funds. As a recipient of grants from various channels and project holders of welfare activities, MHS has to uphold principles of accountability and transparency. One of the strong indicators of these principles is keeping policies and manuals in place, based on which the day-today operations of MHS are to be carried out. In this regard, we have come up with the management and administrative policies for MHS as follows:
        \n1. Purpose and Categories of Personnel
        \n1.1 Purpose
        The purpose of the Personnel Policy is to set down the policies, conditions, rights and obligations of MHS Staffs and Volunteers subject to their performing of the duties and responsibilities in their respective job descriptions. From the time of hiring, each Staffs and Volunteers will have access to this policy, so that he/she can adhere to it with full knowledge and information. The policies described below may at any time be subject to modification if the Board of Members or Senior Management Teams/of MHS deems it necessary. In such cases, employees will be fully informed of the changes made.`
    },
    {
        titleText: 'MHS Volunteers Guidelines',
        contentText: `1. Respect confidentiality and privacy.
        2. Be punctual and reliable.
        3. Carry out the duties listed in your volunteer position description.
        4. Be accountable.
        5. Give notice if your availability changes or you are leaving the MHS.
        6. Report any injuries or hazards that you notice in the workplace.
        7. Respect each others (Especially Women and LGBT)
        8. Store the information of sensitive individuals and organizations carefully.
        9. Approach anything with empathy.
        10. 0bey the principle of research ethics (For Researcher) 
        11. Must respect the traditional culture, religion and existing laws of the relevant country.
        12. must strictly follow the procedures and rules of the schools and regions they visit.
        13. If you want to obtain photographic records, you must obtain the permission of the person in charge.`
    }
]