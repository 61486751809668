import React from 'react'
import { IoLogoFacebook, IoLogoYoutube } from 'react-icons/io5'


const SocialLinks = () => {
    return (
        <div className='flex flex-col'>
            <div className='text-xl text-white font-extrabold'>Social Links</div>
            <div className='flex justify-start mt-5'>
                <div className='cursor-pointer'>
                    <IoLogoFacebook color='#1877F2' fontSize="2rem" />
                </div>
                <div className='cursor-pointer ml-2'>
                    <IoLogoYoutube color='#FF0100' fontSize="2rem" />
                </div>
            </div>
        </div>
    )
}

export default SocialLinks
