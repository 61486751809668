import React, { useState } from "react";
import { IoLogoFacebook, IoLogoYoutube } from "react-icons/io5";
import { Modal } from "flowbite-react";
import Kpay from "../../assets/Kpay.png";
import QrKpay from "../../assets/QrKpay.png";

const Donate = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="flex flex-col">
      <div className="text-xl text-white font-extrabold">Donate Via</div>
      <div className="flex justify-start mt-5">
        {/* <div className="cursor-pointer">
          <IoLogoFacebook color="#1877F2" fontSize="2rem" />
        </div>
        <div className="cursor-pointer ml-2">
          <IoLogoYoutube color="#FF0100" fontSize="2rem" />
        </div> */}
        <div
          className="cursor-pointer w-[33px] h-[33px]  grid items-center  rounded-md overflow-hidden ml-2 "
          onClick={() => setOpenModal(true)}
        >
          <img src={Kpay} className="w-[100%] h-[100%] object-cover" />
        </div>
        <Modal
          dismissible
          show={openModal}
          className=" grid items-center"
          onClose={() => setOpenModal(false)}
        >
          <Modal.Header>
            <h1 className=" text-slate-600">Scan QR to support us</h1>
          </Modal.Header>
          <img src={QrKpay} alt="" width={400} />
        </Modal>
      </div>
    </div>
  );
};

export default Donate;
