export const links = [
    {
        name: 'Event',
        submenu: false,
        navLink: 'event',
        // sublinks: [
        //     {
        //         Head: 'Indoor',
        //         sublink: [
        //             { name: 'Reading Event', link: '/' },
        //             { name: 'National Day', link: '/' },
        //             { name: 'Meditation Day', link: '/' }
        //         ],
        //     },
        //     {
        //         Head: 'Outdoor',
        //         sublink: [
        //             { name: 'Football Event - Men', link: '/' },
        //             { name: 'Football Event - Women', link: '/' },
        //             { name: 'Warming Up Event', link: '/' },
        //         ],
        //     },


        // ]
    },
    {
        name: 'Library',
        submenu: false,
        navLink: 'library'
    },
    {
        name: "Policies", submenu: false,
        navLink: 'policies',

    },
    { name: "Programs", submenu: false, navLink: 'programs' },
    { name: "Gallery", submenu: false, navLink: 'gallery' },

];