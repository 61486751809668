import React, { useContext, useState } from 'react'
import Master from '../layout/Master';
import { useForm, Controller } from 'react-hook-form';
import AuthContext from '../../../context/authContext';
import ButtonLoader from '../../../components/Common/ButtonLoader'
import { Select, Button } from 'flowbite-react'
import { PublisherContextProvider, useCustomPublishers } from '../../../context/publisherContext';
import { useCreateMutation } from '../../../mutations';
import AdminNotAuth from '../AdminNotAuth';

const AdminCreateBooks = () => {
    return (
        <PublisherContextProvider>
            <AdminCreateBooksContent />
        </PublisherContextProvider>
    )
}

const AdminCreateBooksContent = () => {
    const [registerSuccess, setRegisterSuccess] = useState(false);
    const { publishers, isLoading, isSuccess } = useCustomPublishers();

    const { authUser } = useContext(AuthContext);

    // using form hook package for form client validation
    const { register, formState: { errors }, handleSubmit, control } = useForm();

    //react query mutation
    const bookCreateMutation = useCreateMutation("books");

    const onRegisterSubmit = (newBookData) => {
        bookCreateMutation.mutate(newBookData)
    }


    return (
        <Master>
            {!authUser && <AdminNotAuth />}
            {
                authUser && !registerSuccess && <div className='px-5'> <div className='text-primaryColor text-xl font-bold mt-10' >Create New Book</div>
                    <div className='grid md:grid-cols-2 grid-cols-1 gap-5 mt-5'>
                        <div className="col-span-1">
                            <div className="mb-5">
                                <label
                                    htmlFor="book-id"
                                    className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                    Book ID
                                </label>
                                <input
                                    {...register("mhs_bookId", { required: true })}
                                    type="text"
                                    id="book-id"
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    placeholder=""
                                    required=""
                                />
                                {errors.mhs_bookId?.type === 'required' && <span className='text-sm text-red-600'>Enter Book Id</span>}

                            </div>
                            <div className="mb-5">
                                <label
                                    htmlFor="book-name"
                                    className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                    Book Name
                                </label>
                                <input
                                    {...register("book_name", { required: true })}
                                    type="text"
                                    id="book-name"
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    placeholder=""
                                    required=""
                                />
                                {errors.book_name?.type === 'required' && <span className='text-sm text-red-600'>Enter Book Name</span>}

                            </div>

                            <div className="mb-5">
                                <label
                                    htmlFor="book-author"
                                    className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                    Author
                                </label>
                                <input
                                    {...register("book_author", { required: true })}
                                    type="text"
                                    id="book-author"
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    placeholder=""
                                    required=""
                                />
                                {errors.book_author?.type === 'required' && <span className='text-sm text-red-600'>Enter Book Name</span>}

                            </div>




                        </div>
                        <div className='col-span-1'>
                            <div className="mb-5">

                                <label
                                    htmlFor="register-link"
                                    className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                    Publishers
                                </label>

                                {!isLoading && isSuccess &&

                                    <Controller
                                        name="publisher"
                                        control={control}
                                        defaultValue=""
                                        rules={{ required: 'Please select an option' }}
                                        render={({ field }) => (
                                            <div className={`select ${errors.selectOption ? 'is-danger' : ''}`}>
                                                <Select {...field}>
                                                    <option value="" disabled>
                                                        Select an option
                                                    </option>
                                                    <>
                                                        {publishers.map((publisher, index) => (
                                                            <option value={publisher._id} key={index}>{publisher.name}</option>
                                                        ))}

                                                    </>

                                                </Select>
                                            </div>
                                        )}
                                    />
                                }


                                {errors.publisher?.type === 'required' && <span className='text-sm text-red-600'>Enter google form link of event </span>}

                            </div>

                            <div className="mb-5">
                                <label className="relative inline-flex items-center mb-5 cursor-pointer">
                                    <input
                                        {...register("available", { required: false })}
                                        type="checkbox" defaultValue="" className="sr-only peer" />
                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                                    <span className="ms-3 text-sm font-medium text-gray-900 ">
                                        Available
                                    </span>
                                </label>

                            </div>




                        </div>
                    </div>
                    <div className='flex justify-center mt-5'>
                        <Button
                            onClick={handleSubmit(onRegisterSubmit)}
                            type="submit"
                            color="dark"
                        >
                            {bookCreateMutation.isPending && <ButtonLoader />}
                            Create New Book
                        </Button>
                    </div></div>
            }
        </Master>
    )
}

export default AdminCreateBooks
