export const slideInAnimation = {
    hidden: {
      x: -200,
      opacity: 0.5,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
    exit: {
      x: -200,
      opacity: 0.5,
      transition: {
        duration: 0.5,
        ease: "easeIn",
      },
    }
}


export const fadeUpAnimation = {
  hidden: {
    y: 200,
    opacity: 0.5,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
  exit: {
    y: 200,
    opacity: 0.5,
    transition: {
      duration: 0.5,
      ease: "easeIn",
    },
  }
}


