import React, { useRef } from "react";
import OutlineButton from "../Common/OutlineButton";
import Button from "../Common/Button";
import { motion } from "framer-motion";
import { slideInAnimation as cardVariants } from "../../data";
import { useCustomScroll } from "../../context/scrollContext";

const Hero = () => {
  const { scrollTargetRef, scrollToTarget } = useCustomScroll();

  return (
    <div className="h-72 md:h-136 bg-Hero bg-cover font-primary md:bg-top bg-center bg-blend-normal md:mt-20">
      <div className="container mx-auto h-full flex justify-center flex-col md:px-16 px-10">
        <div className="text-lg md:text-3xl text-white font-extrabold mb-2">
          <motion.div initial="hidden" whileInView="visible" exit="exit">
            <motion.div variants={cardVariants} transition={{ delay: 0.2 }}>
              We Rise By Lifting Others
            </motion.div>
          </motion.div>
        </div>
        <motion.div initial="hidden" whileInView="visible">
          <motion.div variants={cardVariants} transition={{ delay: 0.3 }}>
            <div className="md:text-6xl text-2xl font-extrabold mb-5">
              <span className="text-white">Welcome </span>{" "}
              <span className="text-primaryColor">Charity</span>
            </div>
          </motion.div>
        </motion.div>

        <motion.div initial="hidden" whileInView="visible" exit="exit">
          <motion.div variants={cardVariants} transition={{ delay: 0.35 }}>
            <div className="text-white text-xs md:text-base">
              Throughout all of our programs we strive to achieve and maintain
              the <br />
              highest possible outcome through established and validated <br />
              standards.
            </div>
            <div className="flex justify-between md:w-80 w-60">
              <div className="w-44 mt-5" onClick={() => scrollToTarget()}>
                <Button title="Read More" />
              </div>
              <div className="w-44 mt-5">
                <OutlineButton />
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
