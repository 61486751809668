import React from 'react';
import { Button } from 'flowbite-react';
import { HiOutlineArrowLeft, HiOutlineArrowRight } from 'react-icons/hi';

const TableNavigation = ({ pageIndex, pageCount, canPreviousPage, canNextPage, previousPage, nextPage }) => {
    return (
        <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4" aria-label="Table navigation">
            <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">{pageIndex + 1}</span> of{" "}
                <span className="font-semibold text-gray-900 dark:text-white">{pageCount}</span>
            </span>
            <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                <li>
                    <Button size={"xs"} disabled={!canPreviousPage} onClick={previousPage} color='light' className='mr-3'>
                        <HiOutlineArrowLeft className="h-4 w-4" />
                    </Button>
                </li>
                <li>
                    <Button size={"xs"} disabled={!canNextPage} onClick={nextPage} color='dark' className='mr-3'>
                        <HiOutlineArrowRight className="h-4 w-4" />
                    </Button>
                </li>
            </ul>
        </nav>
    );
};

export default TableNavigation;
