import React from 'react'

const TestimonialCard = ({ message, studentName, position, fieldName,  imageUrl }) => {
  return (
      // <div className='h-auto w-full flex items-center'>
      //   <div className='md:px-5 px-0 w-full h-auto bg-cardBackgroundColor  md:rounded-tl-ex-lg rounded-tl-nm rounded-tr-nm rounded-bl-nm rounded-br-nm flex md:flex-row flex-col md:py-0'>
      //     <img className='md:h-48 md:w-48 h-24 w-24 rounded-tl-ex-lg rounded-tr-ex-lg md:rounded-bl-nm rounded-bl-ex-lg rounded-br-ex-lg flex-none md:mx-0 mx-auto' src={imageUrl} alt="profile" />
      //     <div className='flex flex-col px-2 justify-around md:text-start text-center'>
      //       <div className='text-paraText text-sm mt-3 leading-7'>
      //         {trimString(message, 200)}
      //       </div>
      //       <div className='font-extrabold md:mt-0 mt-3'>
      //         <div className='text-xl text-blackColor'>{studentName}</div>
      //         <div className='text-normal text-primaryColor mt-1 mb-3'>{career}</div>
      //       </div>
      //     </div>

      //   </div>
      // </div>
      <div className="pt-32 md:px-6 mx-3">

          <div className="container mx-auto xl:px-32  relative ">
              <div className="lg:hidden flex justify-center absolute top-[-100px] w-full rotate-[6deg] z-10">
                  <img src={imageUrl}
                       className="object-cover z-40 h-32 w-32 rounded-lg shadow-lg dark:shadow-black/20 "
                       alt="image"/>
              </div>
              <div className="flex grid items-center lg:grid-cols-2">
                  <div className="mb-12 md:mt-12 lg:mt-0 lg:mb-0">
                      <div
                          className="relative z-[1] block rounded-lg bg-[hsla(0,0%,100%,0.55)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] backdrop-blur-[25px] dark:bg-[hsla(0,0%,5%,0.7)] dark:shadow-black/20 md:px-12 lg:-mr-14">
                          <h2 className="mb-2 text-3xl font-bold text-primary dark:text-primary-400">
                              {studentName}
                          </h2>
                          <p className="mb-1 text-sm text-paraText font-semibold">
                              {position}
                          </p>
                          <p className="mb-4 text-sm text-paraText">
                              {fieldName && fieldName}
                          </p>

                          <p className="mb-6 text-neutral-500 dark:text-neutral-300">
                              {message}
                          </p>
                          <ul className="flex justify-center lg:justify-start">
                              <li>
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960"
                                       className="w-5 text-primary dark:text-primary-400">
                                      <path fill="gold"
                                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"/>
                                  </svg>
                              </li>
                              <li>
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960"
                                       className="w-5 text-primary dark:text-primary-400">
                                      <path fill="gold"
                                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"/>
                                  </svg>
                              </li>
                              <li>
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960"
                                       className="w-5 text-primary dark:text-primary-400">
                                      <path fill="gold"
                                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"/>
                                  </svg>
                              </li>
                              <li>
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960"
                                       className="w-5 text-primary dark:text-primary-400">
                                      <path fill="gold"
                                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"/>
                                  </svg>
                              </li>
                              <li>
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960"
                                       className="w-5 text-primary dark:text-primary-400">
                                      <path fill="gold"
                                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"/>
                                  </svg>
                              </li>
                          </ul>
                      </div>
                  </div>
                  <div className="md:mb-12 lg:mb-0 hidden lg:block">
                      <img src={imageUrl}
                           className="lg:rotate-[6deg] object-cover h-auto w-auto rounded-lg shadow-lg dark:shadow-black/20"
                           alt="image"/>
                  </div>
              </div>
          </div>
      </div>
      // <div className='w-full h-auto flex items-center justify-center'>
      //   <div className='md:h-96 h-auto w-full md:w-9/12 bg-cardBackgroundColor rounded-lg m-5 flex flex-col justify-center items-center p-20'>
      //     <div className='text-paraText text-sm leading-7 mb-10 text-center'>
      //       {message}
      //     </div>
      //     <img src={imageUrl} alt="image" className='md:h-20 md:w-20 h-16 w-16  rounded-ex-lg' />
      //       <div className='mt-2 text-center'>
      //           <div className='text-lg text-blackColor'>{studentName}</div>
      //           <div className='text-normal text-primaryColor mt-1'>{position}</div>
      //           <div className='text-normal text-primaryColor mt-1'>{fieldName}</div>
      //       </div>
      //   </div>
      // </div>

  )
}

export default TestimonialCard
