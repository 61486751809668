import React from 'react'
import { IoLocationOutline, IoTimeOutline } from 'react-icons/io5'
import { monthSubString } from '../../../services/trimText'
import { getDateFromEvent, getMonthFromEvent, indextoMonth, availableDate } from '../../../services/dateFormatting'

const EventCard = ({ event }) => {
    return (
        <div className='flex items-center py-3 cursor-pointer'>
            <div className={`${availableDate(new Date(event.event_date)) ? 'bg-primaryColor' : 'bg-blackColor'} w-16 h-16 rounded-lg flex flex-none flex-col items-center justify-center`}>
                <div className='text-xl text-white font-extrabold'>{getDateFromEvent(new Date(event.event_date))}</div>
                <div className='text-normal text-white'>{monthSubString(indextoMonth(getMonthFromEvent(new Date(event.event_date))))}</div>
            </div>
            <div className='flex flex-col ml-5'>
                <div className='flex items-center' >
                <div className='mr-2 text-md text-primaryColor font-semibold'>{event.event_title} </div>
                <div className={`text-xs ${event.event_online ? 'text-green-600' : 'text-gray-600'}`}>{event.event_online ? 'online' : 'offline'} </div>
                </div>
                <div className='flex mt-1 items-center'>
                    <div className='text-xs text-paraText flex items-center'>
                        <IoTimeOutline />
                        <div className='ml-1'>{event.event_time}</div>
                    </div>
                    <div className='text-xs text-paraText flex items-center ml-3'>
                        <IoLocationOutline />
                        <div className='ml-1'>{event.event_location}</div>
                    </div>
                    {
                        availableDate(new Date(event.event_date)) ? (<div className="ml-2 px-3 py-1 text-xs font-medium leading-none text-center text-green-800 bg-green-200 rounded-full animate-pulse dark:bg-green-900 dark:text-green-200">Register</div>) : (<div className="ml-2 px-3 py-1 text-xs font-medium leading-none text-center text-gray-800 bg-gray-200 rounded-full">Overdue</div>)
                    }
                </div>
            </div>
        </div>
    )
}

export default EventCard
