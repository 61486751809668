import React from 'react'
import { motion } from 'framer-motion'
import { slideInAnimation as cardVariants } from '../../data/animationVariants'

const ScreenHeader = ({ screenName }) => {
    return (

        <div
            className="h-72 md:h-96 bg-Hero bg-cover font-primary md:bg-top bg-center bg-blend-normal md:mt-20 bg-black/20"
        >
            <div className="container mx-auto h-full flex justify-end py-10 flex-col md:px-16 px-10">

                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    exit="exit"
                >
                    <motion.div variants={cardVariants} transition={{ delay: 0.2 }}>
                        <div className="text-white text-5xl font-ex-bold ">{screenName}</div>
                        <div className='text-xl mt-5'>
                            <span className='text-primaryColor'> Home /</span>
                            <span className='text-white'> {screenName} </span>
                        </div>
                    </motion.div>
                </motion.div>
            </div>

        </div>

    )
}

export default ScreenHeader
