import React, { createContext, useEffect, useState } from 'react';
import axiosClient from '../services/network';

const AuthContext = createContext();

export const AuthContextProvider = (props) => {
    const [authUser, setAuthUser] = useState(false);

    useEffect(() => {
        axiosClient.get('/checkAuth').then(({data}) => {
            if(data == 'not_auth') {
                setAuthUser(false);
                return;
            }
            if(data) {
                setAuthUser(data);
            }
        })
    }, [])
    return (
        <AuthContext.Provider value={{ authUser, setAuthUser}}>
            {props.children}
        </AuthContext.Provider>
    );
}
export default AuthContext;