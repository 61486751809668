export const programCardData = [
    {
        title: "Fitness and Mindfulness",
        iconType: "fitness",
        content: "အမျိုးသမီးများ၏ စိတ်ဖိစီးမှုများနှင့် အိမ်တွင်းအကြမ်းဖက်မှုများကို လျော့ချနိုင်ရန် လေ့ကျင့်လုပ်ဆောင်ပေးနေသည့် အစီအစဉ်ဖြစ်သည်။"
    },
    {
        title: "Library",
        iconType: "library",
        content: "စာဖတ်ခြင်းဖြင့် နှလုံးသားအဟာရ ကြီးထွားကာ  ကိုယ်ချင်းစာမှုနှင့် စာနာမှုကို ပြုစုပျိုးထောင်ပေးသည့်အစီအစဉ်ဖြစ်သည်။"
    },
    {
        title: "Football Club",
        iconType: "football",
        content: " ပြိုင်ပွဲများတွင်  ရွှေ့ပြောင်းကျောင်းသား ကျောင်းသူများ စည်းကမ်းတကျ ယှဉ်ပြိုင်နိုင်ပြီး မှန်ကန်သည့်ယှဉ်ပြိုင်မှုများဖြင့် အရှုံးကိုရင်ဆိုင်ရဲပြီး ဆက်လက်ကြိုးစားလိုစိတ်ကို ပြုစုပျိုးထောင်ပေးသည့် အစီအစဉ်ဖြစ်သည်။"
    },
    {
        title: "Health Education",
        iconType: "warm",
        content: "အခြေခံကျန်းမာရေး ကိုကောင်းစွာ သိရှိပြီး ကိုယ်စိတ်နှစ်ပါး ကျန်းမာကြံ့ခိုင်သူများဖြစ်အောင်  ပြုစုပျိုးထောင်ခြင်းဖြင့် ရွှေ့ပြောင်းကျောင်းသား ကျောင်းသူများ၏ ကျန်းမာရေးအသိကို မြှင့်တင်ရန် ဖြစ်သည်။"
    },
];

