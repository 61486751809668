import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "./routes/routes";
import { motion, useScroll } from "framer-motion";
import { AuthContextProvider } from "./context/authContext";
import { ScrollProvider } from "./context/scrollContext";

const App = () => {
  const { scrollYProgress } = useScroll();
  // const scaleX = useSpring(scrollYProgress)

  const router = createBrowserRouter(routes);
  return (
    // <Routes>
    //   <Route path="/" element={<Root />} />
    //   <Route path="/library" element={<LibraryScreen />} />
    //   <Route path="/event" element={<EventScreen />} />
    //   <Route path="/clubs" element={<ClubsScreen />} />
    //   <Route path="/gallery" element={<GalleryScreen />} />
    //   <Route path="*" element={<NotFoundScreen />} />
    // </Routes>
    <>
      <motion.div
        // className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />
      <AuthContextProvider>
        <ScrollProvider>
          <RouterProvider router={router} />
        </ScrollProvider>
      </AuthContextProvider>
    </>
  );
};

export default App;
