import React, { useContext } from 'react'
import Master from './layout/Master'
import AuthContext from '../../context/authContext'
import { Link } from 'react-router-dom';
import { Button } from "flowbite-react"
import AdminNotAuth from './AdminNotAuth';


const AdminHome = () => {
    const { authUser, setAuthUser } = useContext(AuthContext);

    // const { data: loginUser, isLoading, isError, error, isSuccess } = useQuery({ queryKey: ['loginUser'], queryFn: loginAdmin })
    return (
            <Master>
                {authUser && <div className='text-lg text-green'>
                    <Link to={'/mhs_admin/createEvent'}>
                        <div className='mt-2'>
                            <Button> Create Event</Button>
                        </div>
                    </Link>
                    <Link to={'/mhs_admin/createBook'}>
                        <div className='mt-2'>
                            <Button> Create Book</Button>
                        </div>
                    </Link>
                  

                </div>}
                {!authUser &&
                    <AdminNotAuth />
                }
            </Master>



    )
}

export default AdminHome

