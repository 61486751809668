import { useMutation, useQueryClient } from "@tanstack/react-query"
import axiosClient from "../services/network"

const useCreateMutation = (resourceType, onSuccessCallBack) => {
    const queryCLient = useQueryClient();

    const createMutation = useMutation({
        mutationFn: async(newResourceData) => {
            const response = await axiosClient.post(`/${resourceType}`, newResourceData);
            return response;
        },
        onSuccess: (response, variables, context) => {
            if(onSuccessCallBack) onSuccessCallBack(response.data);
            queryCLient.invalidateQueries({queryKey: [resourceType]});
        }
    })

    return createMutation;
}

export default useCreateMutation;