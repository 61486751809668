import React from 'react'
import Divider from '../Common/Divider'
import { motion } from 'framer-motion'
import { slideInAnimation } from '../../data'
import ContactusForm from '../Common/ContactusForm'
import Donate from '../Common/Donate'
import SocialLinks from '../Common/SocialLinks'

const Footer = () => {
    return (
        <div className='bg-blackColor h-auto w-full font-primary mt-10'>
            <div className='container mx-auto sm:px-5'>
                <div className='grid md:grid-cols-2 grid-cols-1 md:gap-10 gap-2 md:px-0 px-10'>
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        exit="exit"

                    >
                        <motion.div variants={slideInAnimation}>
                            <div className='flex flex-col py-10'>
                                <div className='text-white md:text-3xl text-2xl font-extrabold'>Myanmar Health Support</div>
                                <div className='text-paraText text-sm mt-10'>
                                    We strive to incorporate all the dynamic disciplines that fall under intellectual stimulation in the curriculum and innovative research activities.
                                </div>
                                <div className='text-paraText text-sm mt-3'>
                                    We purposefully designed our dual fitness program, so we have experts to ensure the fitness and academic success of migrant students.
                                </div>
                                <div className='bg-primaryColor h-1 w-20 my-10'></div>
                                <SocialLinks />
                                <div className='bg-primaryColor h-1 w-20 my-10'></div>

                                <Donate />

                            </div>
                        </motion.div>
                    </motion.div>

                    <div className='flex flex-col py-10'>
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            exit="exit"

                        >
                            <motion.div variants={slideInAnimation}>
                                <ContactusForm />
                            </motion.div>
                        </motion.div>
                    </div>

                </div>
                <Divider />
                <div className='text-paraText text-sm text-center pb-5'>
                    © 2023 Myanmar Health Support. All rights reserved.
                </div>
            </div>
        </div>
    )
}

export default Footer
