import React from 'react'
import Navbar from '../NavBar/NavBar'

const Layout = ({ children }) => {
  return (
    <div>
          <div className='container mx-auto'>
            <Navbar />
          </div>
      {children}
    </div>
  )
}

export default Layout
