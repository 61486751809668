import React from 'react'
import Layout from '../../components/Layout/Layout'
import ScreenHeader from '../../components/Common/ScreenHeader'
import EventFilter from '../../components/Common/Event/EventFilter'
import EventsContainer from '../../components/Common/Event/EventsContainer'
import LoadingIndicator from '../../components/Common/LoadingIndicator'

import { EventContextProvider } from '../../context/eventContext'
import { useCustomEvents } from '../../context/eventContext'
import Footer from '../../components/HomePage/Footer'
import ScrollToTop from '../../services/scrollToTop'
import CustomHelmet from '../../components/Common/CustomHelmet'

const EventScreen = () => {
  return (
    <EventContextProvider>
      <EventScreenContent />
    </EventContextProvider>
  );
}

const EventScreenContent = () => {
  const { events, isLoading, isError, error, isSuccess } = useCustomEvents();
  return (
    <Layout>
      <CustomHelmet pageTitle="Events"/>
      <ScrollToTop />
      <ScreenHeader screenName="Event" />
      <div className='mt-10 font-primary container mx-auto sm:px-5 h-auto'>
        <div className='h-auto flex flex-col justify-center items-center'>
          <div className='text-sectionTitle font-semibold'>
            What's <span className='text-primaryColor'>On</span>
          </div>
          <div className='text-paraText text-sm mt-3 text-center'>
            Discover Unforgettable Moments: Explore Our Diverse Range of Engaging and Memorable Events for Every Occasion.
          </div>
        </div>

        {isLoading &&
          <div className='flex justify-center mt-20'>
            <LoadingIndicator />
          </div>}


        {isError && <div>{error.message}</div>}

        {isSuccess && events.length > 0 &&
          <div className='container'>
            <EventFilter />
            <EventsContainer events={events} />
          </div>
        }
        {isSuccess && events.length === 0 &&
          <div className='text-paraText text-center mt-20'>No Events Yet</div>
        }
      </div>
      <Footer />
    </Layout>
  )
}

export default EventScreen
