import React, { useState } from "react";
import { motion } from "framer-motion";
import { slideInAnimation } from "../../data";
import Button from "../Common/Button";
import { Modal } from "flowbite-react";
import QrKpay from "../../assets/QrKpay.png";

const Banner = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="bg-Banner bg-fixed bg-center bg-blend-normal md:h-96 h-72 md:py-0 mt-10 font-primary">
      <div
        className="w-full h-full flex flex-col justify-center items-center 
             bg-primaryColor/80 backdrop-brightness-75"
      >
        <div className="flex justify-center items-center">
          <motion.div initial="hidden" whileInView="visible">
            <motion.div variants={slideInAnimation} transition={{ delay: 0.3 }}>
              <span className="text-white md:text-4xl text-xl w-1/2 text-center font-extrabold">
                Building
              </span>
            </motion.div>
          </motion.div>

          <motion.div initial="hidden" whileInView="visible">
            <motion.div variants={slideInAnimation} transition={{ delay: 0.3 }}>
              <span className="text-blackColor md:text-4xl text-xl w-1/2 text-center font-extrabold ml-2">
                {" "}
                Local Promise
              </span>
            </motion.div>
          </motion.div>
        </div>
        <div className="flex justify-center items-center mt-4">
          <motion.div initial="hidden" whileInView="visible">
            <motion.div variants={slideInAnimation} transition={{ delay: 0.3 }}>
              <span className="text-blackColor md:text-4xl text-xl w-1/2 text-center font-extrabold">
                Around The World
              </span>
            </motion.div>
          </motion.div>
        </div>
        <div className="flex justify-center items-center mt-7">
          <motion.div initial="hidden" whileInView="visible">
            <motion.div
              variants={slideInAnimation}
              transition={{ delay: 0.3 }}
              onClick={() => setOpenModal(true)}
            >
              <Button title="Support Now" black={true} />
            </motion.div>
          </motion.div>
          <Modal
            dismissible
            show={openModal}
            className=" grid items-center"
            onClose={() => setOpenModal(false)}
          >
            <Modal.Header>Scan QR to donate us</Modal.Header>

            <img src={QrKpay} alt="" width={400} />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Banner;
