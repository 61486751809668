import React from 'react'
import Layout from '../../components/Layout/Layout'
import Footer from '../../components/HomePage/Footer'
import ScreenHeader from '../../components/Common/ScreenHeader'
import { getIcon } from '../../services/getIcon'
import CustomHelmet from '../../components/Common/CustomHelmet'
import ScrollToTop from '../../services/scrollToTop'


const ContactCard = ({ title, icon, description }) => {
    return (
        <div className='flex px-5 bg-cardBackgroundColor py-3 w-full mt-6 rounded-lg'>
            <div className='bg-primaryColor w-16 h-16 rounded-lg flex flex-none flex-col items-center justify-center'>
                <div className='text-xl text-white'>
                    {getIcon(icon)}
                </div>
            </div>
            <div className='flex flex-col justify-center ml-5'>
                <div className='mr-2 text-md text-primaryColor font-semibold'>{title} </div>
                <div className='text-paraText text-sm'>{description} </div>
            </div>
        </div>
    )
}

const ContactUsScreen = () => {

    const defaultProps = {
        center: {
            lat: 16.71132941196878,
            lng: 98.55083132466328
        },
        zoom: 11
    };

    return (
        <Layout>
            <ScrollToTop />
            <CustomHelmet pageTitle="Contact" />
            <ScreenHeader screenName="Contact" />
            <div className='mx-auto container mt-10 font-primary sm:px-5'>
                <div>
                    <div className='h-auto flex flex-col justify-center items-center'>
                        <div className='text-sectionTitle font-semibold'>
                            How to <span className='text-primaryColor'>contact us?</span>
                        </div>
                    </div>
                </div>
                <div className='grid lg:grid-cols-2 grid-cols-1 gap-4 mt-10 px-1 lg:px-0'>
                    <div className='px-1'>
                        <ContactCard title="Address" description="17/15 Near Testco Lotus (Big) Mae Sot District, Tak 63110" icon="navigation" />
                        <ContactCard title="Email" description="mhsmyanmar007@gmail.com" icon="email" />
                        <ContactCard title="Phone" description="+66928588943" icon="phone" />

                    </div>
                    <div className="w-full h-auto flex justify-center md:px-0 px-1">
                        <div className="gmap_canvas">
                            <iframe
                                src="https://maps.google.com/maps?q=16.7111085,98.5508206&t=&z=13&ie=UTF8&iwloc=&output=embed"
                              
                                style={{ width: 460, height: 400 }}
                            />
                            <style
                                dangerouslySetInnerHTML={{
                                    __html:
                                        ".mapouter{position:relative;height:400px;width:460px;background:#fff;} .maprouter a{color:#fff !important;position:absolute !important;top:0 !important;z-index:0 !important;}"
                                }}
                            />
                            <a href="https://blooketjoin.org">blooket join</a>
                            <style
                                dangerouslySetInnerHTML={{
                                    __html:
                                        ".gmap_canvas{overflow:hidden;height:400px;width:460px}.gmap_canvas iframe{position:relative;z-index:2}"
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    )
}

export default ContactUsScreen
