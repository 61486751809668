import React from 'react'

const NotFoundScreen = () => {
  
    return (
        <div className='flex justify-center'>
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
          
        </div>
    )
}

export default NotFoundScreen
