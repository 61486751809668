import React, { useState } from 'react'
import EventCard from './EventCard'
import { Link } from 'react-router-dom'
import { EventContextProvider, useCustomEvents } from '../../../context/eventContext'

const EventCardContainer  = () => {
    return (
        <EventContextProvider>
            <EventCardContainerContent />
        </EventContextProvider>
    );
}

const EventCardContainerContent = () => {

    const maxEventsToShow = 3;
    const [showAll, setShowAll] = useState(false);

    const {events, isLoading, isError, error, isSuccess} = useCustomEvents();
   

    return (
        <div className='h-auto w-full bg-cardBackgroundColor rounded-lg p-4 divide-y'>
            {isLoading && <div>Loading</div>}
            {isError && <div>{error.message}</div>}
            {
                isSuccess && (
                    <div>
                        <div className='divide-y'>
                            {
                                events?.slice(0, maxEventsToShow).map((event, index) => (
                                    <EventCard event={event} key={index} />
                                ))
                            }
                             {events.length === 0 && <div>No Events Yet</div>}
                        </div>

                        {events.length > 3 && !showAll && (
                            <Link to='/event'>
                                <div className='w-full bg-white h-auto px-5 py-3 text-center cursor-pointer mt-5 rounded-md'>
                                    <button onClick={() => setShowAll(true)}>See More</button>
                                </div>
                            </Link>
                        )}

                       
                    </div>
                )
            }

        </div>
    )
}

export default EventCardContainer
