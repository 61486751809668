import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/Logo.jpeg'
import NavLinks from './NavLinks'
import Button from '../Common/Button'
import Hamburger from 'hamburger-react'
import useScrollLock from '../../hooks/useScrollLock'

const Navbar = () => {
    const [open, setOpen] = useState(false)
    const [isLocked, toggle] = useScrollLock();
    const handleHomeClick = () => {
        const bodyStyle = document.body.style
        bodyStyle.overflow = 'auto'
    }

    return <nav className='bg-white md:fixed md:w-full md:top-0 md:left-0 md:z-50 md:border-b md:border-gray-200'>
        <div className='flex items-center font-medium justify-around'>
            <div className='z-30 p-5 md:w-auto w-full flex justify-between'>
                <img src={Logo} alt="logo" className='md:cursor-pointer h-12 rounded-full' />
                {/* <div className='text-3xl md:hidden' onClick={() => setOpen(!open)}>
                    <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
                </div> */}
                <div className='text-3xl md:hidden'>
                    <Hamburger toggled={open} onToggle={toggled => {
                        if(toggled) {
                            setOpen(true);
                            toggle(true);
                        } else {
                            setOpen(false);
                            toggle(false);
                        }
                    }} />
                </div>
            </div>
            <ul className='md:flex hidden uppercase items-center gap-8 font-primary'>
                <li>
                    <Link to="/" className='py-7 px-3 inline-block'>
                        Home
                    </Link>
                </li>
                <NavLinks />
            </ul>
            <div className='md:block hidden'>
                <Link to="/contact">
                    <Button title="Contact Us" />
                </Link>
            </div>

            {/* Mobile Nav */}
            <ul className={`
                z-100 md:hidden font-primary bg-white absolute w-full h-full bottom-0 py-24 pl-4 duration-500 ${open ? 'left-0' : 'left-[-100%]'}`}>
                <li>
                    <Link to="/" className='py-7 px-3 inline-block' onClick={handleHomeClick}>
                        Home
                    </Link>
                </li>
                <NavLinks />
                <div className='py-5'>
                    <Link to="/contact">
                        <Button title="Contact Us" />
                    </Link>
                </div>
            </ul>
        </div>
    </nav>
}

export default Navbar;