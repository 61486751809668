import React, { useState } from "react";
import { Link } from "react-router-dom";
import { links } from "./MyLinks";
import useScrollLock from "../../hooks/useScrollLock";

const NavLinks = () => {
    const [heading, setHeading] = useState("");
    const [subHeading, setSubHeading] = useState("");

    const handleLinkClick = () => {
        console.log('link clicked')
        const bodyStyle = document.body.style
        bodyStyle.overflow = 'auto'
    }
    return (
        <>
            {links.map((link, index) => (
                <div key={index}>
                    <div className="px-3 text-left md:cursor-pointer group z-1000" >
                        <h1
                            className="py-7 flex justify-between items-center md:pr-0 pr-5 group"
                            onClick={() => {
                                heading !== link.name ? setHeading(link.name) : setHeading("");
                                setSubHeading("");
                            }}
                        >
                            <Link to={`/${link.navLink}`} onClick={handleLinkClick}>
                                {link.name}

                            </Link>
                            {link.submenu &&

                                <span className="text-xl md:hidden inline">
                                    <ion-icon
                                        name={`${heading === link.name ? "chevron-up" : "chevron-down"
                                            }`}
                                    ></ion-icon>
                                </span>
                            }
                            {link.submenu &&

                                <span className="text-xl md:mt-1 md:ml-2  md:block hidden group-hover:rotate-180 group-hover:-mt-2">
                                    <ion-icon name="chevron-down"></ion-icon>
                                </span>
                            }

                        </h1>
                        {link.submenu && (
                            <div>
                                <div className="absolute top-20 hidden group-hover:md:block hover:md:block">
                                    <div className="py-3">
                                        <div
                                            className="w-4 h-4 left-3 absolute 
                    mt-1 bg-white rotate-45"
                                        ></div>
                                    </div>
                                    <div className={`bg-white p-5 grid ${link.oneGrid ? 'grid-cols-1' : 'grid-cols-2'} gap-10`}>
                                        {link.sublinks.map((mysublinks, index) => (
                                            <div key={index}>
                                                {mysublinks.Head &&
                                                    <h1 className="text-lg font-semibold">
                                                        {mysublinks.Head}
                                                    </h1>
                                                }
                                                {mysublinks.sublink.map((slink, index) => (
                                                    <li className="text-sm text-gray-600 my-2.5" key={index}>
                                                        <Link
                                                            to={slink.link}
                                                            className="hover:text-primaryColor"
                                                        >
                                                            {slink.name}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* Mobile menus */}
                    <div
                        className={`
            ${heading === link.name ? "md:hidden" : "hidden"}
          `}
                    >
                        {link.submenu &&
                            <>
                                {link.sublinks.map((slinks, index) => (
                                    <div key={index}>
                                        <div>
                                            <h1
                                                onClick={() =>
                                                    subHeading !== slinks.Head
                                                        ? setSubHeading(slinks.Head)
                                                        : setSubHeading("")
                                                }
                                                className="py-4 pl-7 font-semibold flex justify-between items-center md:pr-0 pr-5"
                                            >
                                                {slinks.Head}

                                                <span className="text-xl md:mt-1 md:ml-2 inline">
                                                    <ion-icon
                                                        name={`${subHeading === slinks.Head
                                                            ? "chevron-up"
                                                            : "chevron-down"
                                                            }`}
                                                    ></ion-icon>
                                                </span>

                                            </h1>
                                            <div
                                                className={`${subHeading === slinks.Head ? "md:hidden" : "hidden"
                                                    }`}
                                            >
                                                {slinks.sublink.map((slink, index) => (
                                                    <li className="py-3 pl-14" key={index}>
                                                        <Link to={slink.link}>{slink.name}</Link>
                                                    </li>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </>
                        }
                    </div>
                </div>
            ))}
        </>
    );
};

export default NavLinks;