import { useQuery } from '@tanstack/react-query';
import React, { createContext, useContext, useState } from 'react';
import axiosClient from '../services/network';

const EventContext = createContext();

export const EventContextProvider = ({children}) => {

    // for filtering events with online offline
    const [eventSelectedOption, setEventSelectedOption] = useState('All Events');

    // for filter events with date range and date compare
    const [eventDateSelectedOption, setEventDateSelectedOption] = useState('at_any_time');


    const updateEventSelectedOption = (newSelectedOption) => {
        setEventSelectedOption(newSelectedOption);
    }

    // for fetching api
    const fetchEvents = async () => {
        const response = await axiosClient.get('/events')
        return response.data.data
      }

    const {data: events, isLoading, isError, error, isSuccess} = useQuery({ queryKey: ['events'], queryFn: fetchEvents });
    
    return (
        <EventContext.Provider value={{events, isLoading, isError, error, isSuccess, eventSelectedOption, updateEventSelectedOption, eventDateSelectedOption, setEventDateSelectedOption}}>
            {children}
        </EventContext.Provider>
    )
}
export const useCustomEvents = () => useContext(EventContext);