import Layout from "./components/Layout/Layout";
import Hero from "./components/HomePage/Hero";
import WelcomeAboutUs from "./components/HomePage/WelcomeAboutUs";
import Statistic from "./components/HomePage/Statistic";
import VMission from "./components/HomePage/VMission";
import SocialConnect from "./components/HomePage/SocialConnect";
import Banner from "./components/HomePage/Banner";
import Testimonials from "./components/HomePage/Testimonials";
import Footer from "./components/HomePage/Footer";
import ScrollToTop from "./services/scrollToTop";
import { Helmet } from "react-helmet";
import CustomHelmet from "./components/Common/CustomHelmet";


const Root = () => {
    return (
        <Layout>
            <CustomHelmet pageTitle="Home"/>
            <ScrollToTop />
            <Hero />
            <WelcomeAboutUs />
            <Statistic />
            <VMission />
            <SocialConnect />
            <Banner />
            <Testimonials />
            <Footer />
        </Layout>
    )
}

export default Root;
