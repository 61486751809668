export const allProgramCards = [
    {
        title: "Physical Training",
        route: "physical-training",
        iconType: "physical",
        cardContent: "ရွှေ့ပြောင်းကျောင်းများတွင် အခြေခံ ကျန်းမာရေးလေ့ကျင့်ခန်းများကို အတူယှဉ်တွဲ လေ့ကျင့်လုပ်ဆောင်ပေးသည့် အစီအစဉ်ဖြစ်သည်။",
        content: "ရွှေ့ပြောင်းကျောင်းများတွင် အခြေခံ ကျန်းမာရေးလေ့ကျင့်ခန်းများကို အတူယှဉ်တွဲ လေ့ကျင့်လုပ်ဆောင်ပေးသည့် အစီအစဉ်ဖြစ်သည်။",
        programImages: [
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124667/Myanmar%20Health%20Support/programs/physical%20programs/physical-training-1_pfecio.jpg',
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124666/Myanmar%20Health%20Support/programs/physical%20programs/physical-training-2_yvvpdb.jpg',
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124669/Myanmar%20Health%20Support/programs/physical%20programs/physical-training-3_fmb9lk.jpg',
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124668/Myanmar%20Health%20Support/programs/physical%20programs/physical-training-4_yremwl.jpg'
        ]
    },
    {
        title: "Fitness and Mindfulness",
        route: "fitness-and-mindfulness",
        iconType: "fitness",
        cardContent: "အမျိုးသမီးများ၏ စိတ်ဖိစီးမှုများနှင့် အိမ်တွင်းအကြမ်းဖက်မှုများကို လျော့ချနိုင်ရန် လေ့ကျင့်လုပ်ဆောင်ပေးနေသည့် အစီအစဉ်ဖြစ်သည်။",
        content: "အမျိုးသမီးများ၏ စိတ်ဖိစီးမှုများနှင့် အိမ်တွင်းအကြမ်းဖက်မှုများကို လျော့ချနိုင်ရန် လေ့ကျင့်လုပ်ဆောင်ပေးနေသည့် အစီအစဉ်ဖြစ်သည်။",
        programImages: [
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124640/Myanmar%20Health%20Support/programs/fitness-and-mindfulness/fitness-mindfulness-1_lmgskk.jpg',
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124640/Myanmar%20Health%20Support/programs/fitness-and-mindfulness/fitness-mindfulness-2_dvevxr.jpg',
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124639/Myanmar%20Health%20Support/programs/fitness-and-mindfulness/fitness-mindfulness-3_pkhlqb.jpg'
        ]
    },
    {
        title: "Football Club",
        route: "football-club",
        iconType: "football",
        cardContent: "ပြိုင်ပွဲများတွင်  ရွှေ့ပြောင်းကျောင်းသား ကျောင်းသူများ စည်းကမ်းတကျ ယှဉ်ပြိုင်နိုင်ပြီး မှန်ကန်သည့်ယှဉ်ပြိုင်မှုများဖြင့် အရှုံးကိုရင်ဆိုင်ရဲပြီး ဆက်လက်ကြိုးစားလိုစိတ်ကို ပြုစုပျိုးထောင်ပေးသည့် အစီအစဉ်ဖြစ်သည်။",
        content: `
        ပြိုင်ပွဲများတွင်  ရွှေ့ပြောင်းကျောင်းသား ကျောင်းသူများ စည်းကမ်းတကျ ယှဉ်ပြိုင်နိုင်ပြီး မှန်ကန်သည့်ယှဉ်ပြိုင်မှုများဖြင့် အရှုံးကိုရင်ဆိုင်ရဲပြီး ဆက်လက်ကြိုးစားလိုစိတ်ကို ပြုစုပျိုးထောင်ပေးသည့် အစီအစဉ်ဖြစ်သည်။
၁။ကလေးများ နှင့်လူငယ်များ၏ ဘက်စုံဖွံ့ဖြိုးမှုကို အားကစားက အထောက်အကူပေးနိုင်ပြီး ဉာဏ်ရည်ထက်မြက်မှုကို အထောက်အကူပြုစေမည်။
၂။အားကစားလုပ်သည့် လှုပ်ရှားမှုမှန်သမျှ၊ ဆုံးဖြတ်သမျှသည် ကိုယ်တိုင်ဆောင်ရွက်ရခြင်း ဖြစ်သည့်အတွက် ကိုယ်တိုင်ဆုံးဖြတ်နိုင်သည့် အလေ့အကျင့်ကို ရစေမည်။
၃။ အရှုံးသည် အနိုင်ရဖို့ လှေကားထစ်များဖြစ်ကြောင်းကို လက်ခံပြီး ဇွဲမလျှော့သည့် ထက်သန်စိတ်ကို မွေးမြူနိုင်လာမည်။
၄။ကျန်းမာကြံ့ခိုင်သန်မာမှုနှင့် ကြီးထွားဖွံ့ဖြိုးမှုလမ်းစကို ရှာဖွေနိုင်မည်။
၅။အသင်းအပင်း၊ အစုအဖွဲ့နှင့် အချိတ်အဆက်မိမိ လုပ်ငန်းများနှင့် အစီအစဉ်များကို လုပ်ဆောင်လာတတ်စေမည်။
1. Sports can help the comprehensive development of children and young people be intelligent.
2. As you have to perform every sporting activity and decision, you will get the habit of being able to decide for yourself.
3. You will be able to accept that losses are stepping stones to victory. So, you can cultivate a spirit of perseverance.
4. You will be able to find the path to fitness and growth.
5. You will be able to learn to work on your activities and programs in connection with the association.`,
        programImages: [
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124410/Myanmar%20Health%20Support/programs/football-programs/footballp-program-1_m3fsrj.jpg',
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124410/Myanmar%20Health%20Support/programs/football-programs/football-program-2_bkgxlu.jpg',
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124410/Myanmar%20Health%20Support/programs/football-programs/football-program-3_z5jt03.jpg',
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124410/Myanmar%20Health%20Support/programs/football-programs/football-program-4_vnkocq.jpg'
        ]
    },
    {
        title: "Library",
        route: "library",
        iconType: "library",
        cardContent: "စာဖတ်ခြင်းဖြင့် နှလုံးသားအဟာရ ကြီးထွားကာ  ကိုယ်ချင်းစာမှုနှင့် စာနာမှုကို ပြုစုပျိုးထောင်ပေးသည့်အစီအစဉ်ဖြစ်သည်။",
        content: "စာဖတ်ခြင်းဖြင့် နှလုံးသားအဟာရ ကြီးထွားကာ  ကိုယ်ချင်းစာမှုနှင့် စာနာမှုကို ပြုစုပျိုးထောင်ပေးသည့်အစီအစဉ်ဖြစ်သည်။",
        programImages: [
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124483/Myanmar%20Health%20Support/programs/library/library-1_mtuovg.jpg',
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124483/Myanmar%20Health%20Support/programs/library/library-2_fltkfr.jpg',
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124484/Myanmar%20Health%20Support/programs/library/library-3_qsa52d.jpg',
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124484/Myanmar%20Health%20Support/programs/library/library-4_yskb0k.jpg'
        ]
    },
    {
        title: "Research Fellowship",
        route: "research-fellowship",
        iconType: "research",
        cardContent: "အကြောင်းအရာဖြစ်စဉ်များကိုစနစ်တကျချဉ််းကပ်ပြီးသုတေသနရလဒ်မှတ်တမ်းများကိုသက်ဆိုင်ရာနိုင်ငံရေးအဖွဲ့အစည်းနှင့်အစိုးရမဟုတ်သောအဖွဲ့အစည်းများနှင့် အရပ်ဖက်အဖွဲ့စည်းများက ကိုးကား အလေးထားရန် ဖြစ်သည်။",
        content: `အကြောင်းအရာဖြစ်စဉ်များကိုစနစ်တကျချဉ််းကပ်ပြီးသုတေသနရလဒ်မှတ်တမ်းများကိုသက်ဆိုင်ရာနိုင်ငံရေးအဖွဲ့အစည်းနှင့်အစိုးရမဟုတ်သောအဖွဲ့အစည်းများနှင့် အရပ်ဖက်အဖွဲ့စည်းများက ကိုးကား အလေးထားရန် ဖြစ်သည်။

        သုတေသန လုပ်ငန်းများနှင့် အချက်အလက်ကောက်ယူသူများမှသည် ၎င်းတို့၏ လုပ်ငန်းခွင်များ တွင် အထောက်အကူဖြစ်စေပြီး အသက်မွေးဝမ်းကျောင်းလုပ်ငန်းတစ်ခုအဖြစ် ရပ်တည်နိုင်ရန်ဖြစ်သည်။`,
        programImages: [
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124446/Myanmar%20Health%20Support/programs/research-fellowship-programs/research-1_sojb0p.jpg',
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124448/Myanmar%20Health%20Support/programs/research-fellowship-programs/research-2_bu058r.jpg',
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124447/Myanmar%20Health%20Support/programs/research-fellowship-programs/research-3_wc5os9.jpg',
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124448/Myanmar%20Health%20Support/programs/research-fellowship-programs/research-4_f9bg2t.jpg'
        ]
    },
    {
        title: "Health Education",
        route: "health-education",
        iconType: "research",
        cardContent: "အကြောင်းအရာဖြစ်စဉ်များကိုစနစ်တကျချဉ််းကပ်ပြီးသုတေသနရလဒ်မှတ်တမ်းများကိုသက်ဆိုင်ရာနိုင်ငံရေးအဖွဲ့အစည်းနှင့်အစိုးရမဟုတ်သောအဖွဲ့အစည်းများနှင့် အရပ်ဖက်အဖွဲ့စည်းများက ကိုးကား အလေးထားရန် ဖြစ်သည်။",
        content: `အကြောင်းအရာဖြစ်စဉ်များကိုစနစ်တကျချဉ််းကပ်ပြီးသုတေသနရလဒ်မှတ်တမ်းများကိုသက်ဆိုင်ရာနိုင်ငံရေးအဖွဲ့အစည်းနှင့်အစိုးရမဟုတ်သောအဖွဲ့အစည်းများနှင့် အရပ်ဖက်အဖွဲ့စည်းများက ကိုးကား အလေးထားရန် ဖြစ်သည်။

        သုတေသန လုပ်ငန်းများနှင့် အချက်အလက်ကောက်ယူသူများမှသည် ၎င်းတို့၏ လုပ်ငန်းခွင်များ တွင် အထောက်အကူဖြစ်စေပြီး အသက်မွေးဝမ်းကျောင်းလုပ်ငန်းတစ်ခုအဖြစ် ရပ်တည်နိုင်ရန်ဖြစ်သည်။`,
        programImages: [
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124446/Myanmar%20Health%20Support/programs/research-fellowship-programs/research-1_sojb0p.jpg',
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124448/Myanmar%20Health%20Support/programs/research-fellowship-programs/research-2_bu058r.jpg',
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124447/Myanmar%20Health%20Support/programs/research-fellowship-programs/research-3_wc5os9.jpg',
            'https://res.cloudinary.com/myanmar-fixie-nation/image/upload/v1700124448/Myanmar%20Health%20Support/programs/research-fellowship-programs/research-4_f9bg2t.jpg'
        ]
    },
    {
        title: "Public Relation",
        route: "public-relation-&-documentary",
        iconType: "public",
        cardContent: "Coming Soon!",
        content: "Coming Soon!",
        programImages: [],
        available: false
    },
    {
        title: "Vocational Training",
        route: "vocational-training",
        iconType: "vocational",
        cardContent: "Coming Soon!",
        content: "Coming Soon!",
        programImages: [],
        available: false
    }
];