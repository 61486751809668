import React, { useContext, useState } from 'react'
import Master from '../layout/Master'
import AuthContext from '../../../context/authContext'
import { useForm } from 'react-hook-form'
import Datepicker from "react-tailwindcss-datepicker";
import TimePicker from 'react-time-picker'
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { IoCloseOutline } from 'react-icons/io5'
import ButtonLoader from '../../../components/Common/ButtonLoader'
import Notifications from '../admin-components/Notifications'
import { Button } from 'flowbite-react'
import { useCreateMutation } from '../../../mutations'

const AdminCreateEvent = () => {
    const [registerSuccess, setRegisterSuccess] = useState(false);
    const [eventId, setEventId] = useState(null);
    const { authUser } = useContext(AuthContext);
    const [eventDate, setEventDate] = useState();
    const [eventTime, setEventTime] = useState('10:00');
    const [eventImagePreview, setEventImagePreview] = useState('https://cdn.pixabay.com/photo/2017/02/20/18/03/cat-2083492_1280.jpg');


    // using form hook package for form client validation
    const { register, formState: { errors }, handleSubmit } = useForm();

    // react query mutation
    const eventCreateMutation = useCreateMutation("events", (newEventData) => {
        if(newEventData.success) {
            setRegisterSuccess(true);
            setEventId(newEventData.data._id)
        }
    })

    // when register button click, mutate the above createCreateMutation 
    const onRegisterSubmit = newData => {
        const event_time = eventTime;
        const event_date = eventDate.startDate;
        const newModifiedData = { ...newData, event_date, event_time };
        eventCreateMutation.mutate(newModifiedData);

    }

    const handleValueChange = (newEventDate) => {
        setEventDate(newEventDate);
    }


    return (
        <Master>
            {
                authUser && !registerSuccess && <div className='px-5'> <div className='text-primaryColor text-xl font-bold mt-10' >Create New Event</div>
                    <div className='grid md:grid-cols-2 grid-cols-1 gap-5 mt-5'>
                        <div className="col-span-1">
                            <div className="mb-5">
                                <label
                                    htmlFor="event-title"
                                    className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                    Event Title
                                </label>
                                <input
                                    {...register("event_title", { required: true })}
                                    type="text"
                                    id="event-title"
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    placeholder=""
                                    required=""
                                />
                                {errors.event_title?.type === 'required' && <span className='text-sm text-red-600'>Enter Event Title</span>}

                            </div>
                            <div className='mb-5'>
                                <label
                                    htmlFor="message"
                                    className="block mb-2 text-sm font-medium text-gray-900"
                                >
                                    Event Description
                                </label>
                                <textarea
                                    {...register("event_description", { required: true })}
                                    id="message"
                                    rows={4}
                                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                                    placeholder="Your description for Event"
                                    defaultValue={""}
                                />
                                {errors.event_description?.type === 'required' && <span className='text-sm text-red-600'>Enter Event Description</span>}
                            </div>
                            <div className="mb-5">
                                <label
                                    htmlFor="event-location"
                                    className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                    Event Location
                                </label>
                                <input
                                    {...register("event_location", { required: true })}
                                    type="text"
                                    id="event-location"
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    placeholder="eg. Bangkok"
                                    required=""
                                />
                                {errors.event_location?.type === 'required' && <span className='text-sm text-red-600'>Enter Event Location</span>}

                            </div>
                            <div className="mb-5">
                                <label className="relative inline-flex items-center mb-5 cursor-pointer">
                                    <input
                                        {...register("event_online", { required: false })}
                                        type="checkbox" defaultValue="" className="sr-only peer" />
                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                                    <span className="ms-3 text-sm font-medium text-gray-900 ">
                                        Online Event
                                    </span>
                                </label>

                            </div>

                        </div>
                        <div className='col-span-1'>
                            <div className="mb-5">
                                <label
                                    htmlFor="register-link"
                                    className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                    Register Link
                                </label>
                                <input
                                    {...register("online_event_access", { required: false })}
                                    type="text"
                                    id="register-link"
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    placeholder="https://google.form/...."
                                    required=""
                                />
                                {errors.online_event_access?.type === 'required' && <span className='text-sm text-red-600'>Enter google form link of event </span>}

                            </div>
                            <div className="mb-5">
                                <label
                                    htmlFor="date-picker"
                                    className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                    Event Date
                                </label>
                                <Datepicker
                                    primaryColor={'blue'}
                                    useRange={false}
                                    asSingle={true}
                                    value={eventDate}
                                    onChange={handleValueChange}
                                />

                            </div>
                            <div className='mb-5'>
                                <TimePicker onChange={setEventTime} value={eventTime} className="bg-gray-50 border-none text-gray-900 text-sm rounded-lg w-full" />
                            </div>
                            <div className="mb-5">
                                <label
                                    htmlFor="image-link"
                                    className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                    Image Link
                                </label>
                                <input
                                    {...register("event_image_link", { required: true })}
                                    type="text"
                                    id="image-link"
                                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    placeholder="https://res.clounindary.com/..."
                                    required=""
                                    onChange={e => setEventImagePreview(e.target.value)}
                                    value={eventImagePreview}
                                />
                                {errors.event_image_link?.type === 'required' && <span className='text-sm text-red-600'>Enter Event Image Link</span>}

                            </div>
                            {
                                eventImagePreview !== '' &&
                                <div className='relative'>
                                    <img src={eventImagePreview} alt="event image preview" className='object-cover rounded-md w-full h-auto' />
                                    <div className="absolute right-2 top-2">
                                        <button
                                            onClick={() => setEventImagePreview('')}
                                            type="button"
                                            className="hover:text-gray-700 border border-gray-700 bg-gray-700 hover:bg-gray-100 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center"
                                        >
                                            <IoCloseOutline />
                                            <span className="sr-only">Icon description</span>
                                        </button>

                                    </div>
                                </div>
                            }


                        </div>
                    </div>
                    <div className='flex justify-center mt-5'>
                        <Button
                            onClick={handleSubmit(onRegisterSubmit)}
                            type="submit"
                            color='dark'
                        >
                            {eventCreateMutation.isPending && <ButtonLoader />}
                            Create New Event
                        </Button>
                    </div></div>
            }
            {authUser && registerSuccess &&
                <div className='mt-10'>
                    <Notifications eventId={eventId} />
                </div>
            }
            {!authUser &&
                <div className='mt-10 w-full flex justify-center items-center'>
                    Login to continue
                </div>
            }

        </Master>
    )
}

export default AdminCreateEvent
