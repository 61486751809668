import React from 'react'

const PluseLoading = () => {
    return (
        <div className="flex items-center justify-center space-x-2 animate-pulse">
            <div className="w-4 h-4 bg-primaryColor rounded-full" />
            <div className="w-4 h-4 bg-primaryColor rounded-full" />
            <div className="w-4 h-4 bg-primaryColor rounded-full" />
        </div>
    )
}

export default PluseLoading
