import React from 'react'

const Divider = () => {
  return (
    <div className='w-full h-0.5 bg-gray-500 my-5'>
      
    </div>
  )
}

export default Divider
