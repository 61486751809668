import React from 'react'
import EventDetailCardFooter from './EventDetailCardFooter'
import EventCard from '../EventCard'
import { trimString } from '../../../../services/trimText'

const EventDetailCard = ({event}) => {

  const notfoundImage = "https://www.easterntownships.org/files/missing/db6a6449.png";
  
  return (
    <div className="bg-cardBackgroundColor border border-gray-200 rounded-lg">
      
      <img className="rounded-lg h-60 object-cover w-full" src={event.event_image_link ? event.event_image_link : notfoundImage} alt=""/>
    <div className="p-5">
      <EventCard event={event}/>
      <p className="mb-3 text-sm text-gray-700 dark:text-gray-400 ">
        {trimString(event.event_description)}
      </p>
     <EventDetailCardFooter event={event}/>
    </div>
  </div>
  
  )
}

export default EventDetailCard
