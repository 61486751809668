import React from "react";
import BulletText from "../Common/BulletText";
import ProgramCard from "../Common/ProgramCard";
import { motion } from "framer-motion";
import { slideInAnimation, programCardData } from "../../data";
import {
  IoFitnessOutline,
  IoLibraryOutline,
  IoFootballOutline,
  IoHomeOutline,
} from "react-icons/io5";
import { useCustomScroll } from "../../context/scrollContext";

const WelcomeAboutUs = () => {
  const { scrollTargetRef, scrollToTarget } = useCustomScroll();

  const getIcon = (iconType) => {
    switch (iconType) {
      case "fitness":
        return <IoFitnessOutline color="#FF6D01" fontSize="2.5rem" />;
      case "library":
        return <IoLibraryOutline color="#FF6D01" fontSize="2.5rem" />;
      case "football":
        return <IoFootballOutline color="#FF6D01" fontSize="2.5rem" />;
      case "warm":
        return <IoHomeOutline color="#FF6D01" fontSize="2.5rem" />;
      default:
        return <></>;
    }
  };
  return (
    <div
      className="container mx-auto mt-10 font-primary sm:px-5"
      ref={scrollTargetRef}
    >
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 md:px-0 px-10">
        <motion.div initial="hidden" whileInView="visible" exit="exit">
          <motion.div variants={slideInAnimation}>
            <div className="h-auto flex flex-col justify-center">
              <div className="text-sectionTitle font-semibold">
                Welcome <span className="text-primaryColor">About Us</span>
              </div>
              <div className="text-paraText text-sm mt-3">
                Teaching and teaching that will be able to use the hands to work
                and live. Also, communication between social organizations, Work
                skills in management and people management are taught during the
                volunteer period.
              </div>

              <BulletText />
            </div>
          </motion.div>
        </motion.div>
        <motion.div initial="hidden" whileInView="visible" exit="exit">
          <motion.div variants={slideInAnimation}>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-2 md:mt-0 mt-4">
              {programCardData.map((programCardItem, index) => (
                <ProgramCard
                  title={programCardItem.title}
                  content={programCardItem.content}
                  key={index}
                >
                  {getIcon(programCardItem.iconType)}
                </ProgramCard>
              ))}
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default WelcomeAboutUs;
