import React, { useContext, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import axiosClient from '../../../services/network'
import { useForm } from 'react-hook-form'
import ButtonLoader from '../../../components/Common/ButtonLoader'
import Logo from '../../../assets/Logo.jpeg'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../../context/authContext'
import Master from '../layout/Master'


const AdminLogin = () => {

  const { authUser, setAuthUser } = useContext(AuthContext);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const navigate = useNavigate();
  // using form hook package for form client validation
  const { register, formState: { errors }, handleSubmit } = useForm();

  //react query mutation
  const mutation = useMutation({
    mutationFn: async (userData) => {
      try {

        const response = await axiosClient.post('/login', userData)
        return response;

      } catch (err) {
        console.log(err);
      }
    },
    onSuccess: ({ data: user }, variables, context) => {
      if (user.success) {
        setAuthUser(user.data);
        navigate('/mhs_admin');
      }

      if (!user.success && user.responseMsg === 'wrong_password') return notifyError('password')
      if (!user.success && user.responseMsg === 'email_not_found') return notifyError('email')


    }
  })
  // when button click, mutate the above mutation
  const onSubmit = (userData) => {
    mutation.mutate(userData);
  };

  const notifyError = (errType) => {
    if (errType === 'password') {
      setPasswordError(true);
    } else {
      setEmailError(true);
    }
  }



  return (
    <Master>
      {!authUser &&
        <div className="flex flex-col mt-20 items-center justify-center px-6 mx-auto py-0">
          <div
            className="flex items-center mb-6 text-2xl font-semibold text-gray-900 "
          >
            <img
              className="w-12 h-12 mr-2 rounded-full"
              src={Logo}
              alt="logo"
            />
            MHS
          </div>

          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                Sign in to your admin account
              </h1>
              <div className="space-y-4 md:space-y-6">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Your email
                </label>
                <input
                  {...register("email", { required: true })}
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="name@website.com"
                  required=""
                />
                {errors.email?.type === 'required' && <span className='text-sm text-red-600'>Enter your email</span>}
                {emailError && <span className='text-sm text-red-600'>Email Not Found</span>}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 "
                >
                  Password
                </label>
                <input
                  {...register("password", { required: true })}
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required=""
                />
                {errors.password?.type === 'required' && <span className='text-sm text-red-600'>Enter your password</span>}
                {passwordError && <span className='text-sm text-red-600'>Check your password and try again</span>}

              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="remember"
                      aria-describedby="remember"
                      type="checkbox"
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 "
                      required=""
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="remember"
                      className="text-gray-500 "
                    >
                      Remember me
                    </label>
                  </div>
                </div>
              </div>
              <button type="button" className="text-white bg-primaryColor hover:bg-primaryColor-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 w-full" onClick={handleSubmit(onSubmit)}>
                {mutation.isPending && <ButtonLoader />}
                Sign in</button>
            </div>
          </div>
        </div>
      }
      {
        authUser && <div>you have signed in</div>
      }
    </Master>
  )
}

export default AdminLogin
