import React from "react";
import { IoHeartOutline } from "react-icons/io5";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

const BulletText = () => {
  const navigate = useNavigate();

  const BulletTextContent = [
    "Fitness and Mindfulness",
    "Football Club",
    "Research Fellowship Program",
    "Physical Training",
    "Vocational Training",
    "Public Relation and Documentary",
    "Library",
    "Health Education",
  ];
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-3 mt-8">
      {BulletTextContent.map((item, index) => (
        <div className="flex" key={index}>
          <IoHeartOutline color="#FF6D01" fontSize="1.5em" />
          <span className="ml-3 text-sm">{item}</span>
        </div>
      ))}
      <div className="w-44 mt-4" onClick={() => navigate("/programs")}>
        <Button title="Read More" />
      </div>
    </div>
  );
};

export default BulletText;
