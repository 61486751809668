import React from "react";
import { useNavigate } from "react-router-dom";

const OutlineButton = () => {
  const navigate = useNavigate();
  return (
    <div>
      <button
        className="border border-primaryColor hover:bg-primaryColor duration-200 text-white px-6 py-2 rounded-full md:text-base text-xs"
        onClick={() => navigate("/contact")}
      >
        Contact Us
      </button>
    </div>
  );
};

export default OutlineButton;
