import React, { useState } from 'react'
import { IoTrashOutline, IoPencilSharp, IoEye } from 'react-icons/io5'
import { Tooltip } from 'flowbite-react';
import { Link, Navigate } from 'react-router-dom';
import { useDeleteMutation } from '../../../mutations';
import DeleteModal from './DeleteModal';



const ActionButtons = ({ eventOriginalId }) => {

    const [openModal, setOpenModal] = useState(false);

    // delete event
    const eventDeleteMutation = useDeleteMutation('events', setOpenModal)

    const handleEventDelete = () => {
        eventDeleteMutation.mutate(eventOriginalId);
    }
    const handleViewClick = () => {
        return <Navigate to={`/event/${eventOriginalId}`} />
    }




    return (
        <>
            <div className='flex justify-center'>
                <Tooltip content="edit" arrow={false}>
                    <button type="button" data-modal-target="popup-modal" data-modal-toggle="popup-modal" className="text-white bg-blue-400 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 ">
                        <IoPencilSharp />
                        <span className="sr-only">Icon description</span>
                    </button>
                </Tooltip>

                <Tooltip content="view" arrow={false}>
                    <Link to={`/event/${eventOriginalId}`}>
                        <button type="button" className="text-white bg-yellow-400 hover:bg-yellow-500 transition duration-150 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2">
                            <IoEye />
                            <span className="sr-only">Icon description</span>
                        </button>
                    </Link>
                </Tooltip>

                <Tooltip content="delete" arrow={false}>
                    <button type="button" onClick={() => setOpenModal(true)} className="text-white bg-red-400 hover:bg-red-500 transition duration-150 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2">
                        <IoTrashOutline />
                        <span className="sr-only">Icon description</span>
                    </button>
                </Tooltip>


                {/* confirm delete model */}
                <DeleteModal 
                    show={openModal}
                    onClose={() => setOpenModal(false)}
                    onConfirm={() => handleEventDelete()}
                    isPending={eventDeleteMutation.isPending}
                    resourceName={"events"}
                />
               
            </div>
        </>

    )
}

export default ActionButtons
