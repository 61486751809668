import React, { useContext, useState, useEffect } from 'react'
import Master from '../layout/Master';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import AuthContext from '../../../context/authContext';
import ButtonLoader from '../../../components/Common/ButtonLoader'
import { TextInput, Badge, Button } from 'flowbite-react'
import { HiOfficeBuilding, HiOutlineX } from 'react-icons/hi'
import { useCreateMutation } from '../../../mutations';
import AdminNotAuth from '../AdminNotAuth';
import AlertComponent from "../../../components/Common/AlertComponent";
import { PublisherContextProvider, useCustomPublishers } from '../../../context/publisherContext';


const AdminCreatePublisher = () => {
    return (
        <PublisherContextProvider>
            <AdminCreatePublisherContent />
        </PublisherContextProvider>
    )
}



const AdminCreatePublisherContent = () => {

    const {publishers, isLoading } = useCustomPublishers();
    const [showAlert, setShowAlert] = useState(false);
    const [publisher, setPublisher] = useState('');
    const [publisherExistError, setPublisherExistError] = useState(false);
    const [responseDataCount, setResponseDataCount] = useState(0);

    const { authUser } = useContext(AuthContext);

    // using form hook package for form client validation
    const {  control, reset, formState: { errors }, handleSubmit } = useForm();

    // using field array
    const { fields, append, remove } = useFieldArray({
        control,
        name: "publisher"
    })
    useEffect(() => {
        console.log("Fields length:", fields.length);
    }, [fields]);

    const handleSuccess = ({data, success}) => {
        if(success) {
            console.log("Mutation success", data.length)
            setResponseDataCount(data.length)
            setShowAlert(true);
            setPublisherExistError(false);
            setPublisher('');
            reset({
                publisher: []
            });
        }
    }



    const publisherCreateMutation = useCreateMutation("publishers", handleSuccess);




    const onRegisterSubmit = (newPublisherData) => {
        publisherCreateMutation.mutate(newPublisherData);
    }

    const addPublisher = (event) => {
            if(event.key !== 'Enter') {
                return;
            }

            // const isPublisherAlreadyAdded = fields.some(item => item.name === publisher);
            const isPublisherAlreadyAdded = publishers.some(item => item.name === publisher) || fields.some(item => item.name === publisher);
            if (!isPublisherAlreadyAdded) {
                append({ name: publisher });
                setPublisher('');
                setPublisherExistError(false);

            } else {
                setPublisherExistError(true);
            }
    }

    return (
        <Master>
            {!authUser && <AdminNotAuth />}
            {
                authUser && showAlert &&
                <div className="mx-5">
                    <AlertComponent color="success" onDismiss={() => setShowAlert(false)}>
                        <span className="font-bold">{responseDataCount} Documents</span> are inserted successfully.
                    </AlertComponent>
                </div>

            }
            {
                authUser && !isLoading && <div className='px-5'>
                    <div className='text-primaryColor text-xl font-bold mt-10' >Create New Publisher</div>
                    <div className='grid md:grid-cols-2 grid-cols-1 gap-5 mt-5'>
                        <div className="col-span-1">
                            <div className="mb-5">
                                <label
                                    htmlFor="book-name"
                                    className="block mb-2 text-sm font-medium text-gray-900 "
                                >
                                    Publisher Name
                                </label>


                                <TextInput

                                    value={publisher}
                                    onChange={(e) => setPublisher(e.target.value)}
                                    type="text"
                                    id="book-name"
                                    placeholder=""
                                    required=""
                                    onKeyDown={(e) => addPublisher(e)}
                                />
                                 {publisherExistError && <span className='text-xs ml-2 text-red-600'>Publisher Already Exist</span>}

                            </div>
                        </div>
                        <div className='col-span-1'>
                            <div className='flex flex-wrap gap-2'>
                                {fields.map((item, index) => (
                                    <div key={index}>
                                        {/* <Badge icon={HiOfficeBuilding} className='px-3' color={"dark"}
                                            {...register(`publisher.${index}.name`, { required: true })}
                                            >
                                                
                                            </Badge> */}
                                        <Controller

                                            render={({ field }) =>
                                                <div className='relative inline-block group cursor-pointer'>
                                                    <Badge icon={HiOfficeBuilding} color={"dark"} className="px-3">
                                                        {field.value}
                                                    </Badge>
                                                    <div 
                                                        onClick={() => remove(index)}
                                                    size={"xs"} className='flex justify-center items-center cursor-pointer absolute top-[-3px] right-[-1px] opacity-0 group-hover:opacity-100 transition bg-red-600 h-4 w-4 rounded-full'>
                                                        <HiOutlineX className='h-2 w-2 text-white'/>
                                                    </div>
                                                    
                                                </div>
                                            }

                                            name={`publisher.${index}.name`}
                                            control={control}
                                        />

                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center mt-5'>
                        <Button
                            onClick={handleSubmit(onRegisterSubmit)}
                            type="submit"
                            color='dark'
                            disabled={fields.length === 0}
                        >
                            {publisherCreateMutation.isPending && <ButtonLoader />}
                            Create New Publisher

                        </Button>
                    </div></div>
            }
        </Master>
    )
}

export default AdminCreatePublisher
