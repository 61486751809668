import React, { useContext } from 'react'
import Master from '../layout/Master';
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel } from '@tanstack/react-table';
import AuthContext from '../../../context/authContext';
import AdminNotAuth from '../AdminNotAuth';
import { PublisherContextProvider, useCustomPublishers } from '../../../context/publisherContext';
import LoadingIndicator from '../../../components/Common/LoadingIndicator';
import { Button, Select } from 'flowbite-react'
import { Link } from 'react-router-dom';
import PublisherActionButtons from '../admin-components/PublisherActionButtons';
import TableNavigation from "../../../components/Common/TableNavigation";
import Table from "../../../components/Common/Table";

const AdminPublishers = () => {
    return (
        <PublisherContextProvider>
            <AdminPublishersContent />
        </PublisherContextProvider>
    )
}

const AdminPublishersContent = () => {
    const { authUser } = useContext(AuthContext);
    const { publishers, isLoading, isSuccess } = useCustomPublishers();

    const columns = [
        {
            header: 'Id',
            cell: ({ row }) => {
                return Number(row.id) + 1;
            }
        },
        {
            header: "Publisher Name",
            accessorKey: "name",
            footer: "Publisher Name"
        },
        {
            header: "Actions",
            cell: ({ row }) => {
                const publisher_original_id = row.original._id;
                return <PublisherActionButtons publisherOriginalId={publisher_original_id}/>;
            }
        }
    ]

    const publisherTable = useReactTable({
        data: publishers, columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        debugTable: true,
    })

    const publisherButtonLinks = [
        {
            buttonTitle : "New Publisher",
            buttonLink: "/mhs_admin/createPublisher"
        },
    ]

    return (
        <Master>
            {!authUser && 
                <AdminNotAuth />
            }
            {isLoading &&
                <div className='flex justify-center items-center w-full h-screen'>
                    <LoadingIndicator />
                </div>
            }
            {
                authUser && !isLoading &&
                <div className='my-10'>
                    <Table table={publisherTable} tableTitle={"Publishers"} tableButtonLinks={publisherButtonLinks}/>
                </div>
            }
        </Master>
    )
}

export default AdminPublishers
