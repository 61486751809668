import React from "react";
import { Modal, Button, Tooltip } from "flowbite-react"
import { HiOutlineExclamationCircle } from "react-icons/hi";
import ButtonLoader from "../../../components/Common/ButtonLoader";

const DeleteModal = ({ show, onClose, onConfirm, isPending, resourceName }) => {
    return (
        <Modal show={show} size="md" onClose={onClose} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete this {resourceName}?
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={onConfirm} disabled={isPending}>
                {isPending && (
                  <>
                    <ButtonLoader />
                    Deleting
                  </>
                )}
                {!isPending && <div>{"Yes, I'm sure"}</div>}
              </Button>
              <Button color="gray" onClick={onClose}>
                No, cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
  
    )
}

export default DeleteModal