import React from 'react'
import Layout from '../../components/Layout/Layout'
import ScreenHeader from '../../components/Common/ScreenHeader'
import axiosClient from '../../services/network'
import { useParams } from 'react-router-dom'
import EventCard from '../../components/Common/Event/EventCard'
import { useQuery } from '@tanstack/react-query'
import EventCardContainer from '../../components/Common/Event/EventCardContainer'
import Footer from '../../components/HomePage/Footer'
import { availableDate, getDateDifference } from '../../services/dateFormatting'
import ScrollToTop from '../../services/scrollToTop'

const EventDetailScreen = () => {
  const notfoundImage = "https://www.easterntownships.org/files/missing/db6a6449.png";

  const { id: eventId } = useParams();
  const fetchEvent = async () => {
    return await axiosClient.get('/events/' + eventId).then(response => response.data.data)
  }
  const { data: singleEvent, isLoading, isError, error, isSuccess } = useQuery({ queryKey: ['singleEvent'], queryFn: fetchEvent })
  return (
    <Layout>
      <ScrollToTop />
      <ScreenHeader screenName="Event Detail" />
      <div className='mt-10 font-primary container mx-auto h-auto sm:px-5 mb-20'>
        <div className='grid lg:grid-cols-3 md:gap-4 grid-cols-1'>
          <div className='col-span-2'>
            {isSuccess &&
              <img className='w-full h-auto rounded-md' src={singleEvent.event_image_link ? singleEvent.event_image_link : notfoundImage} alt="eventphoto" />
            }
            <div className='mt-10 md:block hidden'>
              <div className='text-sectionTitle font-semibold mb-5'>Event <span className='text-primaryColor'>Description</span> </div>
              <span className='text-sm text-paraText'>
                {isSuccess && singleEvent.event_description}
              </span>
            </div>
          </div>
          <div className='col-span-1 flex justify-center md:mt-0 mt-5'>
            <div>
              <div className='divide-y bg-cardBackgroundColor w-full h-auto px-3 rounded-md'>
                <div>

                  {isLoading && <div>loading event</div>}
                  {isError && <div>{error.message}</div>}
                  {isSuccess &&
                    <EventCard event={singleEvent} />
                  }
                </div>


                {isSuccess &&
                  <div className='pb-4 pt-2'>
                    <span className='text-paraText text-xs'>
                      Contact Email - contact@myanmarhealthsupport.org
                    </span>
                    <div className='font-bold text-xs mt-1'>
                      {availableDate(new Date(singleEvent.event_date)) ? (
                        <div className='text-green-600 flex'>
                          <span>{getDateDifference(singleEvent.event_date)} days left to register</span>
                          <div className='ml-5'>
                            <span>26 Attendees</span>
                          </div>
                        </div>
                      ) : (
                        <div className='text-red-600'>
                          <span>26 Attendees Joined</span>
                        </div>
                      )}

                    </div>
                    {availableDate(new Date(singleEvent.event_date)) && <div className='text-blue-600 font-bold text-xs mt-5 cursor-pointer hover:text-blue-800'>
                      Join Via Zoom
                    </div>}
                    <a href={singleEvent.online_event_access} target='_blank'>
                      <button className={`${availableDate(new Date(singleEvent.event_date)) ? 'bg-primaryColor/80 hover:bg-primaryColor' : 'bg-blackColor cursor-auto'} py-3 w-full h-auto rounded-md text-white cursor-pointer transition duration-100  mt-7`}>
                        Register Event
                      </button>
                    </a>
                  </div>
                }
              </div>

              <div className='mt-10 md:hidden block px-2'>
                <div className='text-sectionTitle font-semibold mb-5'>Event <span className='text-primaryColor'>Description</span> </div>
                <span className='text-sm text-paraText'>
                  {isSuccess && singleEvent.event_description}
                </span>
              </div>

              <div className='mt-10 md:px-0 px-2'>
                <div className='text-sectionTitle font-semibold mb-5'>All <span className='text-primaryColor'>Events</span> </div>
                <EventCardContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default EventDetailScreen
